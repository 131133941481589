<template>
  <div class="jw-diary-list__divider">
    <div class="jw-diary-list__bullet">
      <div class="day" :class="props.isTodayCard && 'green'" v-if="props.dayStart || props.emptyDay === true">
        <!-- <img width="24" height="24" src="~/assets/images/weather-sunny.png" alt="맑음" /> -->
        <img width="24" height="24" v-show="props.weather" :src="`/assets/images/emotion_${props.weather}.png`" alt="행복해요" />
        <!-- <img width="24" height="24" :src="weatherUrl" alt="평범해요" /> -->
        {{ ddActor(props.diarySaveLDate) }}
        <small>{{ dayMatcher(props.wday) }}</small>
        <small v-if="props.emptyDay === false" class="time time-only mt-1">{{ timeActor(props.diarySaveLDate) }}</small>
      </div>

      <div v-else class="time">{{ timeActor(props.diarySaveLDate) }}</div>
    </div>
  </div>
</template>

<script setup>
import dayjs from "dayjs";

const props = defineProps({
  isTodayCard: Boolean,
  emptyDay: Boolean,
  dayStart: Boolean,
  weather: String,
  diarySaveLDate: String,
  wday: [String, Number],
});

function ddActor(date) {
  const dayJsDate = dayjs(date, "YYYYMMDDHHmmss");
  return dayJsDate.format("DD");
}

function dayMatcher(number) {
  const matcher = {
    0: "월요일",
    1: "화요일",
    2: "수요일",
    3: "목요일",
    4: "금요일",
    5: "토요일",
    6: "일요일",
  };
  return matcher[number];
}

function timeActor(dttm) {
  return dayjs(dttm).format("HH:mm");
}
</script>

<style scoped lang="scss"></style>
