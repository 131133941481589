import {
  getMyRoomListAPI,
  getOpenRoomListAPI,
  getRoomDiaryListAPI,
  setFixRoomAPI,
  getRemoteMeetingAPI,
  initRemoteMeetingAPI,
  joinRemoteMeetingAPI,
  getRoomInfoAPI,
  saveRoomNotiAPI,
  updateRoomNotiAPI,
  delRoomNotiAPI,
  searchRoomAPI,
  updateRoomInfoAPI,
  joinRoomAskAPI,
  delRoomAPI,
  selectInviteMemAPI,
  searchChurchAPI,
  getNationListAPI,
  getDenominationListAPI,
  getRegionListAPI,
  makeRoomAPI,
  registGroupAPI,
  changeRoomAlarmAPI,
  changeRoomChiefAPI,
  rejectRoomAPI,
  withDrawRoomAPI,
  respondInvtAPI,
  insertGrpMemAPI,
  getRoomGrpLevelAPI,
} from "./api/sharing";

import dayjs from "dayjs";
import { useAuthStore } from "./auth";

export const useSharingStore = defineStore("sharing", () => {
  const authStore = useAuthStore();
  const state = reactive({
    myRoomList: [],
    openRoomList: [],
    myOpenRoomCnt: 0,
    roomDiaryList: [],
    roomDiaryTitle: "",
    startNum: 0,
    remoteMeeting: {
      openedCnt: 0,
      result: null,
    },
    searchRoomList: [],
    roomInfo: null,
    grpLevelList: [],
    memberList: [],
    churchList: [],
    nationList: [],
    denominationList: [],
    regionList: [],
    makePayload: {
      memCd: "",
      siteLangCd: "", // 언어 코드 COMMONCD001000000001
      roomName: "",
      grpTypeCd: "",
      roomBldLDate: "",
      chiefYn: "Y",
      bldrYn: "Y",
      cnfmYn: "Y", //승인 여부
      cnfmFnshDate: "", // 승인일시(생성일시) YYYYMMDDHHmmss
      invtYn: "Y", // 초대 여부
      roomClosedYn: "N", // 나눔방 폐쇄 여부
      inviteMem: [], // 초대한 나눔방 회원 코드 리스트
    },
  });

  const statusMatcher = {
    joined: "LIVE",
    waiting_asked: "WAITING",
    waiting_invited: "INVITED",
  };

  async function getMyRoomActor() {
    const { statusText, data: res = {} } = await getMyRoomListAPI();
    if (statusText !== "OK" || res.result == undefined) throw Error;
    state.myRoomList = [
      ...res.result.map((itm) => ({
        ...itm,
        jsonData: itm.fdbckData ? JSON.parse(itm.fdbckData) : null,
        //jsonData: JSON.parse(JSON.stringify(itm.fdbckData)),
        status: statusMatcher[itm.gubun],
        lastDate: dayjs(itm.writeLastDate).format("A h:mm"),
        pin: itm.fixedYn === "Y",
        id: itm.roomCd,
        selected: false,
      })),
    ];

    return state.myRoomList;
  }

  async function getOpenRoomActor() {
    const { statusText, data: res = {} } = await getOpenRoomListAPI();
    if (statusText !== "OK" || res.result == undefined) throw Error;
    state.myOpenRoomCnt = res.myOpenRoomCnt || 0;
    state.openRoomList = [
      ...res.result.map((itm) => ({
        ...itm,
        status: statusMatcher[itm.gubun],
      })),
    ];
    return state.openRoomList;
  }

  async function getRoomDiaryListActor({ roomCd, startNum = 0 }) {
    try {
      const { statusText, data: res = {} } = await getRoomDiaryListAPI({
        roomCd,
        startNum: state.startNum,
      });
      if (Number(startNum) == 0) {
        state.roomDiaryList = res.result;
      } else if (Number(startNum) > 0) {
        state.roomDiaryList = [...state.roomDiaryList, ...res.result];
      }
      state.roomDiaryList = state.roomDiaryList.map((itm) => ({
        ...itm,
        imgSrc: !isEmpty(itm.uploadFileCd) ? `https://jwj.kr/upload/diary/${itm.uploadFileCd}_thumb` : "",
        diaryCnts: decodeChar(itm.diaryCnts).replace(/<[^>]*>/g, ""),
        author: {
          memName: itm.memName,
          imgSrc: !isEmpty(itm.profilePhoto) ? `https://jwj.kr/upload/member/${itm.profilePhoto}` : "",
          postCount: itm.postCount,
          likeItCount: itm.amenCount,
        },
      }));

      return state.roomDiaryList;
    } catch (err) {
      console.error(`getRoomDiaryListActor err : `, err);
    }
  }

  async function setFixActor(roomCd, delYn) {
    try {
      const { statusText, data: res = {} } = await setFixRoomAPI({
        roomCd,
        delYn,
      });

      if (res.result == 2) {
        return true;
      }
      return false;
    } catch (err) {
      console.error(`setFixActor err : `, err);
    }
  }

  async function getRemoteMeetingActor(roomCd) {
    try {
      const { statusText, data: res = {} } = await getRemoteMeetingAPI({ roomCd });
      if (res?.openedCnt == undefined) throw Error;
      state.remoteMeeting = { ...res };

      //if (res.result != null) state.remoteMeeting = true;

      return res;
    } catch (err) {
      console.error(`getRemoteMeetingActor err : `, err);
    }
  }
  async function searchRoomActor(roomName) {
    try {
      const { data: res = {} } = await searchRoomAPI({
        roomName,
        siteLangCd: `COMMONCD001000000001`,
      });
      if (!res.result) throw Error;

      const grouped = roomGrpActor(res.result);

      state.searchRoomList = grouped;
      return grouped;
    } catch (err) {
      console.error(`searchRoomActor err : `, err);
    }
  }

  async function initRemoteMeetingActor(payload) {
    const res = await initRemoteMeetingAPI(payload);
    return res;
  }

  async function joinRemoteMeetingActor(payload) {
    const res = await joinRemoteMeetingAPI(payload);
    return res;
  }

  /* function roomGrpActor(list) {
    const grouped = list.reduce((acc, cur) => {
      const index = acc.findIndex((item) => item.name === cur.grpName);
      if (index === -1) {
        acc.push({ name: cur.grpName, id: cur.grpCd, list: [cur] });
      } else {
        acc[index].list.push(cur);
      }
      return acc;
    }, []);
    return grouped;
  } */
  function roomGrpActor(list) {
    const grouped = list.reduce((acc, cur) => {
      const index = acc.findIndex((item) => item.name === cur.grpName);
      if (index === -1) {
        acc.push({ name: cur.grpName, id: cur.grpCd, list: [cur] });
      } else {
        acc[index].list.push(cur);
      }
      return acc;
    }, []);

    // '오픈 나눔방'을 맨 위로 정렬
    grouped.sort((a, b) => {
      if (a.name === "오픈 나눔방") return -1; // '오픈 나눔방'이면 항상 앞으로
      if (b.name === "오픈 나눔방") return 1; // '오픈 나눔방'이 아니면 뒤로
      return 0; // 그 외 경우는 순서 변경 없음
    });

    return grouped;
  }

  async function getRoomInfoActor(roomCd) {
    try {
      const { data: res = {} } = await getRoomInfoAPI(roomCd);

      //state.roomInfo = res.result.find((itm) => itm.roomCd == roomCd.roomCd);
      state.roomInfo = { ...res.info, chiefCd: res.result.find((itm) => itm.chiefYn == "Y")?.memCd || "" };
      state.memberList = [
        ...res.result.map((itm) => ({
          ...itm,
          profileImg: itm.profilePhoto ? `https://jwj.kr/upload${itm.uploadFileSavePath}${itm.profilePhoto}` : null,
        })),
      ];

      return state.roomInfo;
    } catch (err) {
      console.error(`getRoomInfoActor err : `, err);
    }
  }

  async function updateRoomInfoActor(payload) {
    try {
      //console.log("in updateRoomInfoActor payload ==> ", payload);
      const { data: res = {} } = await updateRoomInfoAPI(payload);
      //await getMyRoomActor();
      state.roomInfo = { ...state.roomInfo, ...payload };
      //console.log("updateRoomInfoActor res : ", res);
      return res;
    } catch (err) {
      console.error(`updateRoomInfoActor err : `, err);
    }
  }

  // 오픈 나눔방에 가입 요청
  async function joinOpenRoomAskActor(obj) {
    try {
      /* const payload = {
        grpCd,
        roomCd,
        sendMemCd,
        memCd,
        selfAgreeYn: "Y",
        cnfmFnshDate: dayjs().format("YYYYMMDDHHmmss"),
        cnfmYn: "Y",
        invtYn: "Y",
      }; */
      const { data: res = {} } = await joinRoomAskAPI(obj);
      return res;
    } catch (err) {
      console.error(`joinOpenRoomAskActor err : `, err);
    }
  }

  async function joinRoomAskActor(payload) {
    const { data: res = {} } = await joinRoomAskAPI(payload);
    return res;
  }

  async function selectInviteMemActor(roomCd) {
    try {
      const { data: res = {} } = await selectInviteMemAPI(roomCd);

      //state.memberList = res.result;
      return res;
    } catch (err) {
      console.error(`selectInviteMemActor err : `, err);
    }
  }

  /**
   * @param {string} grpName - 검색할 교회/단체 이름
   */
  async function searchChurchActor(grpName) {
    try {
      if (!authStore.userInfo.memCd) throw Error("searchChurchActor : 회원코드가 없습니다.");
      if (!grpName) throw Error("searchChurchActor : 검색어가 없습니다.");

      const { data: res = {} } = await searchChurchAPI({ grpName, memCd: authStore.userInfo.memCd, siteLangCd: "COMMONCD001000000001" });
      state.churchList = res.groupList.map((itm) => ({ name: itm.grpName, address: itm.belongCity, leader: itm.ldrName, grpCd: itm.grpCd }));
      return res;
    } catch (err) {
      console.error("searchChurchActor error:", err.message, err);
    }
  }

  async function getNationListActor() {
    try {
      const { data: res = {} } = await getNationListAPI();
      state.nationList = res.result.map((itm) => ({ ...itm, text: itm.koreanName, value: itm.nationCd }));
      //console.log("state.nationList :: ", state.nationList);
      return state.nationList;
    } catch (err) {
      console.error(`getNationListActor err : `, err);
    }
  }

  async function getDenominationListActor() {
    const { data: res = {} } = await getDenominationListAPI();

    state.denominationList = res.codeList.map((itm) => ({ ...itm, text: itm.commonCdName, value: itm.commonCd }));
    return state.denominationList;
  }

  async function getRegionListActor() {
    const { data: res = {} } = await getRegionListAPI();
    state.regionList = res.codeList.map((itm) => ({ ...itm, text: itm.commonCdName, value: itm.commonCd }));
    return state.regionList;
  }

  async function makeRoomActor(payload) {
    const res = await makeRoomAPI(payload);
    return res;
  }

  async function registGroupActor(payload) {
    const res = await registGroupAPI(payload);
    return res;
  }

  async function changeRoomAlarmActor(payload) {
    const res = await changeRoomAlarmAPI(payload);
    return res;
  }

  async function setRoomAlarmActor(payload) {
    const res = await saveUserSettingAPI(payload);
    return res;
  }

  async function changeMemIvtAuthActor(payload) {
    const res = await updateRoomInfoAPI(payload);
    return res;
  }

  async function saveRoomNotiActor(payload) {
    const res = await saveRoomNotiAPI(payload);
    return res;
  }

  async function updateRoomNotiActor(payload) {
    const res = await updateRoomNotiAPI(payload);
    return res;
  }

  async function delRoomNotiActor(payload) {
    const res = await delRoomNotiAPI(payload);
    return res;
  }
  async function changeRoomChiefActor(payload) {
    const res = await changeRoomChiefAPI(payload);
    return res;
  }

  async function rejectRoomActor(payload) {
    const res = await rejectRoomAPI(payload);
    return res;
  }

  async function withDrawRoomActor(payload) {
    const res = await withDrawRoomAPI(payload);
    return res;
  }

  async function respondInvtActor(payload) {
    const res = await respondInvtAPI(payload);
    return res;
  }

  async function insertGrpMemActor(payload) {
    const res = await insertGrpMemAPI(payload);
    return res;
  }

  async function delRoomActor(payload) {
    const res = await delRoomAPI(payload);
    return res;
  }

  /**
   * @param {string} roomCd
   * @param {string} grpCd
   *  */
  async function getRoomGrpLevelActor(payload) {
    const { data: res = {} } = await getRoomGrpLevelAPI(payload);
    state.grpLevelList = res.result;
    //console.log("getRoomGrpLevelActor res :: ", res);
    return state.grpLevelList;
  }

  return {
    state,
    getMyRoomActor,
    getOpenRoomActor,
    getRoomDiaryListActor,
    setFixActor,
    getRemoteMeetingActor,
    searchRoomActor,
    getRoomInfoActor,
    updateRoomInfoActor,
    joinOpenRoomAskActor,
    joinRoomAskActor,
    selectInviteMemActor,
    searchChurchActor,
    getNationListActor,
    getDenominationListActor,
    getRegionListActor,
    makeRoomActor,
    registGroupActor,
    changeRoomAlarmActor,
    changeMemIvtAuthActor,
    saveRoomNotiActor,
    updateRoomNotiActor,
    delRoomNotiActor,
    changeRoomChiefActor,
    rejectRoomActor,
    withDrawRoomActor,
    respondInvtActor,
    insertGrpMemActor,
    delRoomActor,
    initRemoteMeetingActor,
    joinRemoteMeetingActor,
    getRoomGrpLevelActor,
  };
});
