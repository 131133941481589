<template>
  <v-snackbar
    v-model="snackbar"
    timeout="-1"
    location="top"
    rounded="lg"
    color="#1B6BC9"
    transition="scroll-y-transition"
    content-class="jw-today-snackbar"
    max-width="90vw"
    :min-width="isPc ? '820px' : '90vw'"
    :style="{ 'margin-top': isPc ? '50px' : '55px' }"
  >
    <div class="jw-today-snackbar--contents">
      <div class="text">{{ myAlarm?.mssgTitle }}</div>
      <v-spacer></v-spacer>
      <template v-if="isTwoBtn">
        <v-btn variant="outlined" height="25" rounded="xl" class="px-2 mr-2" @click.stop="btnActor(true)">승인</v-btn>
        <v-btn variant="outlined" height="25" rounded="xl" class="px-2" @click.stop="btnActor(false)">거절</v-btn>
      </template>
      <v-btn v-else variant="outlined" height="25" rounded="xl" class="px-2" @click.stop="btnActor(true)">확인</v-btn>
    </div>
  </v-snackbar>
</template>

<script setup>
import { useTodayStore } from "~/store/today";
import { useAuthStore } from "~/store/auth";
import { useDisplay } from "vuetify";
import { todayAlarmResAPI } from "~/store/api/today";

const todayStore = useTodayStore();
const authStore = useAuthStore();
const { xlAndUp } = useDisplay();

const props = defineProps({
  showSnackBar: Boolean,
});

const snackbar = ref(true);
const isPc = computed(() => xlAndUp.value);
const myAlarm = computed(() => todayStore.state.myAlarm);
const isAlarm = computed(() => !!myAlarm.value);

const twoBtnList = ["alarm001", "alarm005", "alarm008", "alarm012", "alarm016", "alarm023"];
//const isTwoBtn = computed(() => twoBtnList.includes(myAlarm.value?.alarmGubun));
const isTwoBtn = computed(() => {
  if (!myAlarm?.value) return false;

  if (myAlarm.value.fdbckNotiCd) {
    return myAlarm.value.mssgRspYn == "N" ? true : false;
  }
  return false;
});

watch(
  () => props.showSnackBar,
  (val) => {
    if (!val) {
      snackbar.value = false;
    }
  }
);

watch(
  isAlarm,
  (val) => {
    snackbar.value = val;
  },
  { immediate: true, deep: true }
);

const msgMatcher = (code) => {
  const mappings = {
    alarm001: { allowCode: "alarm002", denyCode: "alarm003" },
    alarm005: { allowCode: "alarm006", denyCode: "alarm007" },
    alarm008: { allowCode: "alarm009", denyCode: "alarm010" },
    alarm012: { allowCode: "alarm013", denyCode: "alarm014" },
    alarm016: { allowCode: "alarm017", denyCode: "alarm018" },
    alarm023: { allowCode: "alarm024", denyCode: "alarm025" },
  };
  return mappings[code];
};

const btnActor = async (isApproved) => {
  const { alarmGubun } = myAlarm.value;
  const actions = {
    alarm001: () => grpActor(isApproved ? myAlarm.value.confirmCode.Y : myAlarm.value.confirmCode.N), // 공동체 가입 신청
    alarm005: () => roomJoinActor(isApproved ? myAlarm.value.confirmCode.Y : myAlarm.value.confirmCode.N), // 나눔방 초대 승인
    alarm008: () => roomJoinActor(isApproved ? myAlarm.value.confirmCode.Y : myAlarm.value.confirmCode.N), // 나눔방 가입 신청
    alarm012: () => roomChiefActor(isApproved ? myAlarm.value.confirmCode.Y : myAlarm.value.confirmCode.N),
    alarm016: () => diaryActor(isApproved ? myAlarm.value.confirmCode.Y : myAlarm.value.confirmCode.N),
    alarm023: () => grpActor(isApproved ? myAlarm.value.confirmCode.Y : myAlarm.value.confirmCode.N),
  };
  if (actions[alarmGubun]) {
    await actions[alarmGubun]();
  }
};

const grpActor = async (code) => {
  await todayAlarmResAPI({ ...commonPayload(), alarmGubun: code });
  code == "alarm002" ? alert("공동체 가입이 승인되었습니다.") : alert("공동체에 가입 신청이 거절되었습니다.");

  snackbar.value = false;
};

const roomJoinActor = async (code) => {
  await todayAlarmResAPI({ ...commonPayload(), alarmGubun: code, roomCd: myAlarm.value.roomCd });
  ["alarm006", "alarm009"].includes(code) ? alert("나눔방 가입이 승인되었습니다.") : alert("나눔방 가입 신청이 거절되었습니다.");

  snackbar.value = false;
};

const roomChiefActor = async (code) => {
  /*
  alarmGubun: alarm014
sendMemCd: 20221227101711222803
rcvMemCd: 533
siteLangCd: COMMONCD001000000001
grpCd: K_etc001
roomCd: 20240215153815303817
diaryCd:
mssgReplyCnts:
mssgWriteDate: 20240608175536
diaryRcmdCd:
  */
  await todayAlarmResAPI({ ...commonPayload(), alarmGubun: code, roomCd: myAlarm.value.roomCd });
  code == "alarm013" ? alert("나눔방장 권한이 부여되었습니다.") : alert("나눔방장 권한이 거절되었습니다.");
  snackbar.value = false;
};

const diaryActor = async (code) => {
  await todayAlarmResAPI({
    ...commonPayload(),
    alarmGubun: code,
    /* roomCd: myAlarm.value.roomCd,
    diaryCd: myAlarm.value.diaryCd,
    diaryRcmdCd: myAlarm.value.diaryRcmdCd,
    rcmdTypeCd: myAlarm.value.rcmdTypeCd,
    rcmdRsn: myAlarm.value.rcmdRsn, */
  });
  code == "alarm017" ? alert("일기 추천이 완료되었습니다.") : alert("일기 추천이 거절되었습니다.");

  snackbar.value = false;
};

const commonPayload = () => ({
  //sendMemCd: myAlarm.value.sendMemCd, 로그인한 사용자 memCd
  sendMemCd: authStore.userInfo.memCd,
  rcvMemCd: myAlarm.value.rcvMemCd,
  ...(myAlarm.value.siteLangCd && { siteLangCd: myAlarm.value.siteLangCd }),
  ...(myAlarm.value.roomCd && { roomCd: myAlarm.value.roomCd }),
  ...(myAlarm.value.mssgWriteDate && { mssgWriteDate: myAlarm.value.mssgWriteDate }),
  ...(myAlarm.value.grpCd && { grpCd: myAlarm.value.grpCd }),
  ...(myAlarm.value.diaryCd && { diaryCd: myAlarm.value.diaryCd }),
  ...(myAlarm.value.mssgReplyCnts && { mssgReplyCnts: myAlarm.value.mssgReplyCnts }),
  ...(myAlarm.value.diaryRcmdCd && { diaryRcmdCd: myAlarm.value.diaryRcmdCd }),
  ...(myAlarm.value.rcmdTypeCd && { rcmdTypeCd: myAlarm.value.rcmdTypeCd }),
  ...(myAlarm.value.rcmdRsn && { rcmdRsn: myAlarm.value.rcmdRsn }),
  ...(myAlarm.value.notiMssgCd && { pNotiMssgCd: myAlarm.value.notiMssgCd }),
});
</script>
