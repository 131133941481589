<template>
  <div class="jw-diary-list__card empty">
    <!-- <v-btn variant="text" class="btn-write" @click.stop="toWritePage"> -->
    <v-btn variant="text" class="btn-write">
      <!-- 일기쓰기 -->
      작성된 일기가 없습니다.
      <!-- <VIcon size="14" color="#C4C4C4">jwIcons:svg/circle-plus-solid</VIcon> -->
    </v-btn>
  </div>
</template>

<script setup>
import dayjs from "dayjs";
const router = useRouter();
const props = defineProps({
  emptyDay: Boolean,
  diarySaveLDate: String,
});

function toWritePage() {
  //console.log("toWritePage props ::: ", props);
  return false;
  router.push({ path: "/write", query: { date: `${props.diarySaveLDate}${dayjs().format("HHmmss")}` } });
  //router.push({ path: "/write", query: { date: props.diarySaveLDate } });
}
</script>

<style scoped lang="scss"></style>
