/* import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();

  // 로그인 상태 확인
  //const { data: authResult } = await useAsyncData('chkLogin', () => authStore.chkLoginStatusActor());

  const authResult = await authStore.chkLoginStatusActor();

  let isLoggedIn = true;
  //const isLoggedIn = authResult.value;
  if ([undefined, null, "pending", false].includes(authResult)) {
    isLoggedIn = false;
  }

  // 로그인 상태에서 로그인 또는 시작 페이지로 이동하는 경우
  if (["/", "/signin"].includes(to.path) && isLoggedIn) {
    return navigateTo("/today", { replace: true });
  }

  // 시작 페이지로 이동하는 경우, 로그인 페이지로 리디렉션
  if (to.path === "/" && !isLoggedIn) {
    return navigateTo("/signin");
  }

  const requiredAuthList = ["room-view", "diary", "write", "room"];

  // 로그인이 필요한 페이지에 대한 접근 제어

  if (requiredAuthList.includes(to.name) && !isLoggedIn) {
    return navigateTo("/signin");
  }

  // 로그인 상태에서 로그인 필요 페이지에 접근하는 경우, 현재 페이지 유지
  if (requiredAuthList.includes(to.name) && isLoggedIn) {
    return;
  }
  // 다른 경우엔 특별한 리디렉션 없음
});
 */

import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();

  // 로그인 상태 확인
  const { data: authResult, error } = await useAsyncData("chkLogin", () => authStore.chkLoginStatusActor());

  let isLoggedIn = true;
  if (error.value || [undefined, null, "pending", false].includes(authResult.value)) {
    isLoggedIn = false;
  }

  // 로그인 상태에서 로그인 또는 시작 페이지로 이동하는 경우
  if (["/", "/signin"].includes(to.path) && isLoggedIn) {
    return navigateTo("/today", { replace: true });
  }

  // 시작 페이지로 이동하는 경우, 로그인 페이지로 리디렉션
  if (to.path === "/" && !isLoggedIn) {
    return navigateTo("/signin");
  }

  const requiredAuthList = ["room-view", "diary", "write", "room"];

  // 로그인이 필요한 페이지에 대한 접근 제어
  if (requiredAuthList.includes(to.name) && !isLoggedIn) {
    return navigateTo("/signin");
  }

  // 로그인 상태에서 로그인 필요 페이지에 접근하는 경우, 현재 페이지 유지 (아무 것도 반환하지 않음)
});
