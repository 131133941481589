import axios from "axios";
import { objToFormData } from "~/utils/utils";

// /ajax/util/selectMyGroupDashboard.do

const axiosInstance = function (url) {
  const hostname = window.location.hostname;
  let baseURL;
  if (hostname == "localhost") {
    baseURL = url || "/api";
  } else {
    baseURL = `https://jwj.kr${url || "/api"}`;
  }

  return axios.create({
    baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

/**
 * @title [033] 내 알림 카운트 API
 */
export const getAlarmNotiCountAPI = async () => {
  try {
    return await axiosInstance("/ajax").get("/util/selectAllGetCount.do");
  } catch (err) {
    console.error(`getAlarmNotiCountAPI err : `, err);
    throw err;
  }
};

/**
 * @title [022] 알림 목록 API
 * @startNum : 0
 * @endNum : 1
 * @mssgChkYn : 메시지 상태, N
 */
export const getAlarmListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").get("/util/selectAlarmList.do", {
      params: { ...payload },
    });
  } catch (err) {
    console.error(`getAlarmListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [022-01] 알림 상세 API
 * @notiMssgCd : 알림메시지코드
 */
export const getAlarmDetailInfoAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").get("/util/selectAlarmInfoDet.do", {
      params: { ...payload },
    });
  } catch (err) {
    console.error(`getAlarmDetailInfoAPI err : `, err);
    throw err;
  }
};

/**
 * @title [085] 알림 메시지 저장 API(승인/거절)
 */
export const insertAlarmProcAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/util/insertAlarmProc.do", objToFormData(payload));
  } catch (err) {
    console.error(`insertAlarmProcAPI err : `, err);
    throw err;
  }
};

/**
 * @title [085-01] 알림 메시지 읽은 후 읽음처리 API
 * @notiMssgCd : 알림메시지코드
 * @mssgChkYn : 메시지읽음여부:Y
 */
export const updateAlarmInfoAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/util/updateAlarmProc.do", objToFormData(payload));
  } catch (err) {
    console.error(`updateAlarmInfoAPI err : `, err);
    throw err;
  }
};

/**
 * @title [101] 공지사항 목록 API
 */
export const getNoticeListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").get("/util/selectNoticeList.do", {
      params: { ...payload },
    });
  } catch (err) {
    console.error(`getNoticeListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [101-1] 공지사항 읽음 저장 API
 */
export const updateNoticeReadAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/util/updatePostProc.do", objToFormData(payload));
  } catch (err) {
    console.error(`updateNoticeReadAPI err : `, err);
    throw err;
  }
};

/**
 * @title [003] 배너 목록 API
 * */
export const getBannerListAPI = async () => {
  try {
    return await axiosInstance().get(`/util/selectBannerList.do`);
  } catch (err) {
    console.error(`getDailyListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [107] 설정 정보 API
 */
export const getSettingInfoAPI = async () => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectSettingInfo.do`);
  } catch (err) {
    console.error(`getUserSettingAPI err : `, err);
    throw err;
  }
};

/**
 * @title [108] 설정 정보 저장 API
 * @memCd : 회원코드
 * @siteAlarmYn : 일기작성시간 알람설정 여부(Y/N)
 * @alarmDate : 일기작성시간(hhmm)
 * @pushAlarmRcv : 위지엠 공지 알람 여부(Y/N)
 */
export const updateSettingInfoAPI = async (payload) => {
  return axiosInstance("/ajax").post(`/usr/updateUserProc.do`, objToFormData(payload));
};

/**
 * @title [001] 업로드 아바타 API
 */
export const uploadAvatarImageAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/usr/updateUserProfileProc.do", objToFormData({ ...payload }));
};

/**
 * @title [001] 다국어 목록 API
 */
export const getLanguageListAPI = async () => {
  try {
    return await axiosInstance("").post(`/common/selectLanguageList.do`);
  } catch (err) {
    console.error(`getLanguageListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [075] 내 나눔방 목록 API
 */
export const getMyRoomListAPI = async () => {
  return axiosInstance("/ajax").get(`/room/selectMyRoomMainList.do`);
};

/**
 * @title [110] 나눔방 전체 알람 설정 저장 API
 */
export const updateSettingRoomAlarmAllAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/updateRoomAlarmAll.do`, objToFormData(payload));
  } catch (err) {
    console.error(`updateSettingRoomAlarmAllAPI err : `, err);
    throw err;
  }
};

/**
 * @title [111] 언어 설정 저장 API
 */
export const updateSettingLanguageAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/usr/updateUserLangProc.do`, objToFormData(payload));
  } catch (err) {
    console.error(`updateSettingLanguageAPI err : `, err);
    throw err;
  }
};

/**
 * @title [104] 온라인헌금 계좌 API
 */
export const getMyGroupAccountAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectMyGroupAccount.do`, objToFormData(payload));
  } catch (err) {
    console.error(`getMyGroupAccountAPI err : `, err);
    throw err;
  }
};

/**
 * @title [100] 내 교회/단체 대시보드 API
 */
export const getMyGroupDashboardAPI = async () => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectMyGroupDashboard.do`);
  } catch (err) {
    console.error(`getMyGroupDashboardAPI err : `, err);
    throw err;
  }
};

/**
 * @title [097-1] 내 교회/단체 대시보드 설정 API
 */
export const updateMyGroupDashboardAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/grp/updateGrpMemDisplay.do`, objToFormData(payload));
  } catch (err) {
    console.error(`updateMyGroupDashboardAPI err : `, err);
    throw err;
  }
};

/**
 * @title [096] 내 교회/단체 목록 API
 */
export const getMyGroupListAPI = async () => {
  try {
    return await axiosInstance("/ajax").post(`/grp/selectMyGrpList.do`);
  } catch (err) {
    console.error(`getMyGroupListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [097-1] 내 교회/단체 대시보드 설정 API
 */
export const updateMyGroupListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/grp/saveGrpOrder.do`, objToFormData(payload));
  } catch (err) {
    console.error(`updateMyGroupListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [103] 주보 API
 */
export const getMyGroupJuboListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectMyGroupJuboList.do`, objToFormData(payload));
  } catch (err) {
    console.error(`getMyGroupJuboListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [102] 설교 영상 API
 */
export const getMyGroupSermonListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/selectMyGroupSermonList.do`, objToFormData(payload));
  } catch (err) {
    console.error(`getMyGroupSermonListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [114] 동역 목록 API
 */
export const getFellowListAPI = async () => {
  try {
    return await axiosInstance("/ajax").get("/util/selectFellowList.do");
  } catch (err) {
    console.error(`getFellowListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [115] 동역 상세 API
 */
export const getFellowDetailAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").get("/util/selectFellowDet.do", {
      params: { ...payload },
    });
  } catch (err) {
    console.error(`getFellowDetailAPI err : `, err);
    throw err;
  }
};

/**
 * @title [106] 회원 탈퇴 API
 */
export const cancelMembershipAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/usr/updateMemberWithDraw.do", objToFormData(payload));
  } catch (err) {
    console.error(`cancelMembershipAPI err : `, err);
    throw err;
  }
};
