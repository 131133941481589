import dayjs from "dayjs";
//import dayjs from 'dayjs-nuxt';

//const dayjs = useDayjs();

export const objToFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    if (object[key] !== undefined) formData.append(key, object[key] ? object[key] : "");
    return formData;
  }, new FormData());

export const isEmpty = (value) => ["", " ", null, "null", undefined, "undefined"].includes(value);
export const isFalsy = (value) => ["", " ", null, "null", undefined, "undefined", false, "false"].includes(value);
export const isZero = (value) => ["", " ", null, "null", undefined, "undefined", 0, "0", false, "false"].includes(value);

export function getTimeDiff(timeToCompare) {
  const timeDiffDuration = dayjs.duration(dayjs().diff(timeToCompare));
  const yearDiff = parseInt(timeDiffDuration.format("Y"));
  const monthDiff = parseInt(timeDiffDuration.format("M"));
  const dateDiff = parseInt(timeDiffDuration.format("D"));
  const hourDiff = parseInt(timeDiffDuration.format("H"));
  const minuteDiff = parseInt(timeDiffDuration.format("m"));
  const secondDiff = parseInt(timeDiffDuration.format("s"));

  if (yearDiff > 0) {
    return `${yearDiff}년 전`;
  } else if (monthDiff > 0) {
    return `${monthDiff}달 전`;
  } else if (dateDiff > 0) {
    return `${dateDiff}일 전`;
  } else if (hourDiff > 0) {
    return `${hourDiff}시간 전`;
  } else if (minuteDiff > 0) {
    return `${minuteDiff}분 전`;
  } else if (secondDiff > 0) {
    return `${secondDiff}초 전`;
  } else {
    return "";
  }
}

export const regexList = {
  //id: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,12}$/, // 영문,숫자 조합 6~12자
  id: /^[a-zA-Z\d]{6,12}$/, //영문, 숫자 옵션 6~12자
  pw: /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*\/])[a-z\d!@#$%^&*\/]{8,}$/, //숫자,소문자,특수문자 조합 8자 이상
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  phone: /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
};

export const isEmptyTrimList = (list) => {
  try {
    // list의 요소들을 isEmpty, trim으로 검사한다.
    for (let i = 0, max = list.length; i < max; i++) {
      if (isEmpty(list[i]) || list[i].trim().length === 0) {
        return true;
      }
    }
    return false;
  } catch (err) {
    console.error(`isEmptyTrimList err : `, err);
  }
};

export const displayName = (fullName) => {
  return fullName.substring(0, 1).toLocaleUpperCase("en-US");
};

export const dateForm = (date, form) => {
  return dayjs(date).format(form);
};

export const decodeChar = (text) => {
  const entities = [
    ["&amp;", "&"],
    ["&#35;", "#"],
    ["&lt;", "<"],
    ["&gt;", ">"],
    ["&quot;", '"'],
    ["&#39;", "'"],
    ["&#37;", "%"],
    ["&#40;", "("],
    ["&#41;", ")"],
    ["&#43;", "+"],
    ["&#47;", "/"],
    ["&#46;", "."],
    ["&#59;", ";"],
    ["&nbsp;", " "],
  ];

  return entities.reduce((decodedText, [entity, char]) => {
    return decodedText.replace(new RegExp(entity, "gi"), char);
  }, text);
};

export function parseDateTime(dateTimeStr) {
  // 입력 문자열에서 년, 월, 일, 시, 분, 초를 추출합니다.
  const year = parseInt(dateTimeStr.substring(0, 4), 10);
  const month = parseInt(dateTimeStr.substring(4, 6), 10) - 1; // JavaScript의 월은 0부터 시작합니다.
  const day = parseInt(dateTimeStr.substring(6, 8), 10);
  const hour = parseInt(dateTimeStr.substring(8, 10), 10);
  const minute = parseInt(dateTimeStr.substring(10, 12), 10);
  const second = parseInt(dateTimeStr.substring(12, 14), 10);

  // 추출한 값을 사용하여 Date 객체를 생성합니다.
  const date = new Date(year, month, day, hour, minute, second);
  return date;
}

// 오늘 날짜 가져오기
const today = new Date();

// 입력된 8자리 숫자를 Date 객체로 변환하는 함수
function parseDate(input) {
  const year = parseInt(input.substring(0, 4), 10);
  const month = parseInt(input.substring(4, 6), 10) - 1; // 월은 0부터 시작
  const day = parseInt(input.substring(6, 8), 10);
  return new Date(year, month, day);
}

// 나이 계산 함수
function calculateAge(birthDate, today) {
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

// 14세 미만인지 여부를 판별하는 함수
export function isUnder14(input) {
  const birthDate = parseDate(input);
  const age = calculateAge(birthDate, today);
  return age < 14;
}

// 18세 이하인지 여부를 판별하는 함수
export function isUnder18(input) {
  const birthDate = parseDate(input);
  const age = calculateAge(birthDate, today);
  return age <= 18;
}

export const formatTimeUnit = (value) => (value.toString().length === 1 ? "0" + value : value);
