import {
  getAlarmNotiCountAPI,
  getAlarmListAPI,
  getAlarmDetailInfoAPI,
  updateAlarmInfoAPI,
  insertAlarmProcAPI,
  getNoticeListAPI,
  updateNoticeReadAPI,
  getBannerListAPI,
  getSettingInfoAPI,
  updateSettingInfoAPI,
  uploadAvatarImageAPI,
  getLanguageListAPI,
  getMyRoomListAPI,
  updateSettingRoomAlarmAllAPI,
  updateSettingLanguageAPI,
  getMyGroupAccountAPI,
  getMyGroupDashboardAPI,
  updateMyGroupDashboardAPI,
  getMyGroupListAPI,
  updateMyGroupListAPI,
  getMyGroupJuboListAPI,
  getMyGroupSermonListAPI,
  getFellowListAPI,
  getFellowDetailAPI,
  cancelMembershipAPI
} from "~/store/api/more";
import dayjs from "dayjs";

import { isEmpty, decodeChar } from "~/utils/utils";

export const useMoreStore = defineStore("more", {
  state: () => ({
    orderModalOpen: false,
    orderModalClose: false,
    drag: false,
    myGroupList: [],
    dashboardList: [],

    alarmNotiCount: {
      alarm: 0,
      noti: 0,
    },

    alarmList: [],
    alarmPaging: {
      // lastIdx: 0,
      isEnd: false,
    },
    alarmPayload: {
      startNum: 0,
      /* endNum: 1,
      mssgChkYn: "N", */
    },

    noticeList: [],
    noticePaging: {
      // lastIdx: 0,
      isEnd: false,
    },
    noticePayload: {
      startNum: 0,
    },

    sermonGrpName: "",
    sermonList: [],
    sermonPaging: {
      // lastIdx: 0,
      isEnd: false,
    },
    sermonPayload: {
      grpCd: "",
      startNum: 0,
      endNum: 5,
    },

    bannerList: [],

    langList: [],

    settingInfo: {
      loginId: "", // 성명
      memName: "", // 성명
      nickName: "", // 닉네임
      memContact: "", // 휴대폰번호
      memSex: "M", // 성별
      memBirthday: "", // 생년월일(19990101)
      memEmail: "", // 이메일
      profilePhoto: "", // 프로필사진
      chrchName: "", // 출석교회
      selectedLangCd: "",
      selectedLangName: "",
      pushAlarmRcv: true,
      alarmDate: "",
      siteAlarmYn: false,
      roomAlarmYn: false,
      roomAlarmCnt: 0
    },
  }),
  actions: {
    // 새 알림, 공지 개수
    async getAlarmNotiCount() {
      try {
        const { statusText, data: res = {} } = await getAlarmNotiCountAPI();
        if (statusText !== "OK" || res?.alarm === undefined || res?.noti === undefined) throw Error;

        // this.alarmNotiCount.alarm = 3;
        // this.alarmNotiCount.noti = 2;

        this.alarmNotiCount.alarm = res.alarm;
        this.alarmNotiCount.noti = res.noti;
      } catch (err) {
        console.error(`getAlarmNotiCount err : `, err);
      }
    },
    stringActor(string) {
      return string.replace(/\"/g, "");
    },

    // 알림목록
    async getAlarmList() {
      try {
        if (isEmpty(this.alarmList) || this.alarmList.length === 0) {
          this.alarmPayload.startNum = 0;
          // this.alarmPaging.lastIdx = 0;
          this.alarmPaging.isEnd = false;
        }

        if (this.alarmPaging.isEnd === false) {
          if (!isEmpty(this.alarmList) && this.alarmList.length > 0) this.alarmPayload.startNum += 20;

          const { data: res = {} } = await getAlarmListAPI(this.alarmPayload);
          if (res?.result === undefined) throw Error;

          if (res.result.length < 20) this.alarmPaging.isEnd = true;

          this.alarmList = [
            ...this.alarmList,
            ...res.result.map((itm) => {
              const mssgTitle = this.stringActor(itm.mssgTitle);
              // const mssgCnts = !isEmpty(itm.mssgCnts) ? this.stringActor(itm.mssgCnts) : this.stringActor(itm.mssgTitle);
              const mssgWriteDate = dayjs(itm.mssgWriteDate).format("YY/MM/DD - hh:mm");
              const mssgChkYn = itm.mssgChkYn === "Y";
              const mssgRspYn = itm.mssgRspYn === "Y";

              let fdbckNotiCd = {},
                fdbckConfirm = false; // feedback(승인/거절) 여부

              if (!isEmpty(itm.fdbckNotiCd) && !mssgRspYn) {
                fdbckNotiCd = itm.fdbckNotiCd.split(",").reduce(function (obj, str, index) {
                  let strParts = str.split(":");
                  if (strParts[0] && strParts[1]) obj[strParts[0].trim()] = strParts[1].trim();
                  return obj;
                }, {});

                fdbckConfirm = true;
              }

              return {
                // ...itm,
                // alarmGubun: itm.alarmGubun,
                notiMssgCd: itm.notiMssgCd,
                roomCd: itm.roomCd,
                grpCd: itm.grpCd,
                fdbckNotiCd: fdbckNotiCd,
                fdbckConfirm: fdbckConfirm,
                mssgCnts: "",
                mssgWriteDate: mssgWriteDate,
                mssgChkYn: mssgChkYn,
                mssgTitle: mssgTitle,
              };
            }),
          ];

          // this.alarmPaging.lastIdx++;

          // return res;
        }
      } catch (err) {
        console.error(`getAlarmList err : `, err);
      }
    },

    // 알림상세
    async getAlarmDetailInfo(payload) {
      try {
        const { statusText, data: res = {} } = await getAlarmDetailInfoAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`getAlarmDetailInfo err : `, err);
      }
    },

    // 알림 읽음처리
    async updateAlarmInfo(payload) {
      try {
        const { statusText, data: res = {} } = await updateAlarmInfoAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`updateAlarmInfo err : `, err);
      }
    },

    // 알림 승인/거절 처리
    async insertAlarmProc(payload) {
      try {
        const { statusText, data: res = {} } = await insertAlarmProcAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`insertAlarmProc err : `, err);
      }
    },

    // 공지사항
    async getNoticeList() {
      try {
        if (isEmpty(this.noticeList) || this.noticeList.length === 0) {
          this.noticePayload.startNum = 0;
          // this.noticePaging.lastIdx = 0;
          this.noticePaging.isEnd = false;
        }

        if (this.noticePaging.isEnd === false) {
          if (!isEmpty(this.noticeList) && this.noticeList.length > 0) this.noticePayload.startNum += 20;

          const stripPattern = /(<([^>]+)>)/gi;
          // const hrefPattern = /<a[^>]*href=['"]([^'"]*)['"][^>]*>/;
          // const srcPattern = /<img[^>]*src=['"](\/\/[^'"]+)['"][^>]*>/;
          const { data: res = {} } = await getNoticeListAPI(this.noticePayload);
          if (res?.result === undefined) throw Error;

          if (res.result.length < 20) this.noticePaging.isEnd = true;

          this.noticeList = [
            ...this.noticeList,
            ...res.result.map((itm) => {
              // const hrefMatch = itm.postCnts.match(hrefPattern);
              // const srcMatch = itm.postCnts.match(srcPattern);
              const postCnts = itm.postCnts.replace(stripPattern, "");
              // const link = hrefMatch?.[1] ? hrefMatch[1] : "https://jwj.kr";
              // const image = srcMatch?.[1] ? "https:" + srcMatch[1] : "";

              // if(image != "") {
              //   postContents = postContents + "<a href='" + link + "' target='_blank'>";
              //   postContents = postContents + "<img src='" + image + "'>";
              //   postContents = postContents + "</a>";
              // }

              const postDate = dayjs(itm.regLDate).format("YY/MM/DD");
              const isNew = itm.postChkYn == "N" ? true : false;

              return {
                ...itm,
                postCnts: postCnts,
                postDate: postDate,
                isNew: isNew,
              };
            }),
          ];

          // this.noticePaging.lastIdx++;

          // return res;
        }
      } catch (err) {
        console.error(`getNoticeList err : `, err);
      }
    },

    // 공지사항 읽음 처리
    async updateNoticeRead(payload) {
      try {
        const { statusText, data: res = {} } = await updateNoticeReadAPI(payload);
        if (statusText == "OK" && !res.result) payload.gubun = "";

        return res;
      } catch (err) {
        console.error(`updateNoticeRead err : `, err);
      }
    },

    // 배너
    async getBannerList() {
      try {
        const hrefPattern = /<a[^>]*href=['"]([^'"]*)['"][^>]*>/;
        const srcPattern = /<img[^>]*src=['"](\/\/[^'"]+)['"][^>]*>/;
        const { statusText, data: res = {} } = await getBannerListAPI();
        if (statusText !== "OK" || res?.bannerList === undefined) throw Error;

        this.bannerList = [
          ...res.bannerList.map((itm) => {
            const hrefMatch = itm.postCnts.match(hrefPattern);
            const srcMatch = itm.postCnts.match(srcPattern);
            return {
              ...itm,
              link: hrefMatch?.[1] ? hrefMatch[1] : "https://jwj.kr",
              image: srcMatch?.[1] ? "https:" + srcMatch[1] : "",
            };
          }),
        ];
      } catch (err) {
        console.error(`getBannerList err : `, err);
      }
    },

    // 설정 정보
    async getSettingInfo() {
      try {
        const { statusText, data: res = {} } = await getSettingInfoAPI();
        if (statusText !== "OK" || res?.user === undefined || res?.roomAlarmCnt === undefined) throw Error;
        const { data: resLang = {} } = await getLanguageListAPI();
        const { data: resRoomList = {} } = await getMyRoomListAPI();  // 내 나눔방 목록

        let loginId = res.user.loginId,
          memName = res.user.memName,
          nickName = res.user.nickName,
          memSex = res.user.memSex,
          memBirthday = res.user.memBirthday,
          memEmail = res.user.memEmail,
          phoneNumber = res.user.memContact,
          chrchName = res.user.chrchName,
          profilePhoto = !isEmpty(res.user.profilePhoto) ? "https://jwj.kr/upload/member/" + res.user.profilePhoto : "",
          selectedLangCd = res.user.selectedLangCd, // 언어코드
          pushAlarmRcv = res.user.pushAlarmRcv === "Y", // 위지엠 공지 알림 여부
          alarmDate = res.user.alarmDate, // 일기쓰기시간
          siteAlarmYn = res.user.siteAlarmYn === "Y", // 일기쓰기시간 알림 여부
          roomAlarmYn = res.roomAlarmCnt, // 나눔방 알림 설정 여부
          roomCnt = resRoomList.length;

        if (phoneNumber.match(/^\d{11}$/))
          phoneNumber = phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 7) + "-" + phoneNumber.substring(7);

        // 언어명
        let selectedLangName = "";
        resLang.langList.some((list) => {
          if (list.commonCd == selectedLangCd) {
            selectedLangName = list.commonCdName;
            return true;
          }
        });

        this.settingInfo = {
          loginId: loginId, // 아이디
          memName: memName, // 성명
          nickName: nickName, // 별명
          memSex: memSex, // 성별
          memBirthday: memBirthday, // 생년월일
          memEmail: memEmail, // 이메일
          phoneNumber: phoneNumber, // 휴대폰번호
          profilePhoto: profilePhoto, // 프로필사진
          chrchName: chrchName, // 출석교회
          selectedLangCd: selectedLangCd, // 언어코드
          selectedLangName: selectedLangName, // 언어
          pushAlarmRcv: pushAlarmRcv,
          alarmDate: alarmDate,
          siteAlarmYn: siteAlarmYn,
          roomAlarmYn: roomAlarmYn,
          roomCnt: roomCnt
        };
      } catch (err) {
        console.error(`getSettingInfo err : `, err);
      }
    },

    // 설정 정보 저장
    async updateSettingInfo(payload) {
      try {
        const savePayload = {
          memCd: payload.memCd,
        };
        if (payload.myPledge) savePayload.myPledge = payload.myPledge;

        if (!isEmpty(payload.alarmDate)) savePayload.alarmDate = payload.alarmDate;
        if (!isEmpty(payload.siteAlarmYn)) savePayload.siteAlarmYn = payload.siteAlarmYn ? "Y" : "N";
        if (!isEmpty(payload.pushAlarmRcv)) savePayload.pushAlarmRcv = payload.pushAlarmRcv ? "Y" : "N";

        const { statusText, data: res = {} } = await updateSettingInfoAPI(savePayload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result; // 0 or 1
      } catch (err) {
        console.error(`updateSettingInfo err : `, err);
      }
    },

    // 프로필 이미지 저장
    async uploadAvatarImage(payload) {
      try {
        const { statusText, data: res = {} } = await uploadAvatarImageAPI({ ...payload });
        if (statusText !== "OK" || res === undefined) throw Error;

        if (res.result) res.profilePhoto = "https://jwj.kr/upload/member/" + res.profilePhoto;

        return res;
      } catch (err) {
        console.error(`uploadAvatarImage err : `, err);
      }
    },

    // 나눔방 알람 설정 저장
    async updateSettingRoomAlarmAll(payload) {
      try {
        const isAlarmRcv = payload.alarmRcvYn,
          savePayload = {
            alarmRcvYn: isAlarmRcv ? "Y" : "N",
          };

        const { statusText, data: res = {} } = await updateSettingRoomAlarmAllAPI(savePayload);
        if (statusText == "OK" && !res.result) payload.alarmRcvYn = !isAlarmRcv;

        return res;
      } catch (err) {
        console.error(`updateSettingRoomAlarmAll err : `, err);
      }
    },

    // 언어 목록 정보
    async getLanguageList() {
      try {
        const { statusText, data: res = {} } = await getLanguageListAPI();
        if (statusText !== "OK" || res?.langList === undefined) throw Error;

        this.langList = res.langList;
      } catch (err) {
        console.error(`getLanguageList err : `, err);
      }
    },

    // 언어 설정 저장
    async updateSettingLanguage(payload) {
      try {
        const { statusText, data: res = {} } = await updateSettingLanguageAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`updateSettingLanguage err : `, err);
      }
    },

    // 온라인헌금 계좌 정보
    async getMyGroupAccount(payload) {
      try {
        const { statusText, data: res = {} } = await getMyGroupAccountAPI(payload);
        // if (statusText !== "OK" || res?.result === undefined) throw Error;
        if (statusText !== "OK") throw Error;

        return res;
      } catch (err) {
        console.error(`getMyGroupAccount err : `, err);
      }
    },

    // 주보
    async getMyGroupJuboList(payload) {
      try {
        const { statusText, data: res = {} } = await getMyGroupJuboListAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res;
      } catch (err) {
        console.error(`getMyGroupJuboList err : `, err);
      }
    },

    // 설교
    async getMyGroupSermonList(payload) {
      try {
        if (isEmpty(this.sermonList) || this.sermonList.length === 0) {
          this.sermonPayload.startNum = 0;
          // this.sermonPaging.lastIdx = 0;
          this.sermonPaging.isEnd = false;
        }

        if (this.sermonPaging.isEnd === false) {
          if (!isEmpty(this.sermonList) && this.sermonList.length > 0) this.sermonPayload.startNum += 10;

          this.sermonPayload.grpCd = payload.grpCd;

          const { data: res = {} } = await getMyGroupSermonListAPI(this.sermonPayload);
          if (res?.result === undefined) throw Error;

          if (res.result.length < 10) this.sermonPaging.isEnd = true;

          this.sermonGrpName = res.grpName;

          this.sermonList = [
            ...this.sermonList,
            ...res.result.map((itm) => {
              let videoLink = itm.videoLink;
              if (videoLink === undefined) videoLink = "";

              itm.video = getVideoInfo(videoLink);

              return itm;
            }),
          ];
        }
      } catch (err) {
        console.error(`getMyGroupSermonList err : `, err);
      }
    },

    // 내 교회/단체 대시보드 정보(메인 대시보드)
    async getMyGroupDashboard() {
      /* this.dashboardList = [
        {
          info: {
            grpContact: "01076814003",
            nationCd: "KR",
            accountGuide: "계좌 이체시 이름을 적어 주세요.",
            ldrName: "김다위",
            grpCd: "447",
            chrchCd: "1",
            grpName: "선한목자교회★",
            grpTypeCd: "GrpType0010000000001",
            homepage: "https://gsmch.org",
            displayYn: "Y"
          },
          jubo: {
            up: 1,
            uploadFileCd: "/upload/column/20240127160538441836"
          },
          accounts: {
            bank: "국민",
            accountCd: "1",
            accountType: "주일헌금",
            depositor: "OO교회",
            accountNumber: "123-456-7890",
            accountCnt: 1
          },
          sermon: {
            bible: "이사야 60:1-3",
            sermonCd: "1",
            sermonDate: "2024-01-28",
            videoLink: "https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe",
            video: getVideoInfo("https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe"),
            up: 1,
            title: "회개의 기쁨을 찾아라",
            sermonType: "주일설교"
          }
        },
        {
          info: {
            grpContact: "01076814003",
            nationCd: "KR",
            accountGuide: "계좌 이체시 이름을 적어 주세요.",
            ldrName: "김다위",
            grpCd: "547",
            chrchCd: "1",
            grpName: "목자교회★",
            grpTypeCd: "GrpType0010000000001",
            homepage: "https://gsmch.org",
            displayYn: "N"
          },
          jubo: {
            up: 1,
            uploadFileCd: "/upload/column/20240127160538441836"
          },
          accounts: {
            bank: "국민",
            accountCd: "1",
            accountType: "주일헌금",
            depositor: "OO교회",
            accountNumber: "123-456-7890",
            accountCnt: 1
          },
          sermon: {
            bible: "이사야 60:1-3",
            sermonCd: "1",
            sermonDate: "2024-01-28",
            videoLink: "https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe",
            video: getVideoInfo("https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe"),
            up: 1,
            title: "회개의 기쁨을 찾아라",
            sermonType: "주일설교"
          },
          links: [{
            linkName: "test",
            link: "https://www.naver.com"
          }]
        },
        {
          info: {
            grpContact: "01076814003",
            nationCd: "KR",
            accountGuide: "계좌 이체시 이름을 적어 주세요.",
            ldrName: "김다위",
            grpCd: "647",
            chrchCd: "1",
            grpName: "교회★",
            grpTypeCd: "GrpType0010000000001",
            homepage: "https://gsmch.org",
            displayYn: "Y"
          },
          jubo: {
            up: 1,
            uploadFileCd: "/upload/column/20240127160538441836"
          },
          accounts: {
            bank: "국민",
            accountCd: "1",
            accountType: "주일헌금",
            depositor: "OO교회",
            accountNumber: "123-456-7890",
            accountCnt: 1
          },
          sermon: {
            bible: "이사야 60:1-3",
            sermonCd: "1",
            sermonDate: "2024-01-28",
            videoLink: "https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe",
            video: getVideoInfo("https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe"),
            up: 1,
            title: "회개의 기쁨을 찾아라",
            sermonType: "주일설교"
          },
          links: [{
            linkName: "test",
            link: "https://www.naver.com"
          }]
        },
        {
          info: {
            grpContact: "01076814003",
            nationCd: "KR",
            accountGuide: "계좌 이체시 이름을 적어 주세요.",
            ldrName: "김다위",
            grpCd: "747",
            chrchCd: "1",
            grpName: "목자★",
            grpTypeCd: "GrpType0010000000001",
            homepage: "https://gsmch.org",
            displayYn: "N"
          },
          jubo: {
            up: 1,
            uploadFileCd: "/upload/column/20240127160538441836"
          },
          accounts: {
            bank: "국민",
            accountCd: "1",
            accountType: "주일헌금",
            depositor: "OO교회",
            accountNumber: "123-456-7890",
            accountCnt: 1
          },
          sermon: {
            bible: "이사야 60:1-3",
            sermonCd: "1",
            sermonDate: "2024-01-28",
            videoLink: "https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe",
            video: getVideoInfo("https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe"),
            up: 1,
            title: "회개의 기쁨을 찾아라",
            sermonType: "주일설교"
          },
          links: [{
            linkName: "test",
            link: "https://www.naver.com"
          }]
        },
        {
          info: {
            grpContact: "01076814003",
            nationCd: "KR",
            accountGuide: "계좌 이체시 이름을 적어 주세요.",
            ldrName: "김다위",
            grpCd: "847",
            chrchCd: "1",
            grpName: "목자",
            grpTypeCd: "GrpType0010000000001",
            homepage: "https://gsmch.org",
            displayYn: "N"
          },
          jubo: {
            up: 1,
            uploadFileCd: "/upload/column/20240127160538441836"
          },
          accounts: {
            bank: "국민",
            accountCd: "1",
            accountType: "주일헌금",
            depositor: "OO교회",
            accountNumber: "123-456-7890",
            accountCnt: 1
          },
          sermon: {
            bible: "이사야 60:1-3",
            sermonCd: "1",
            sermonDate: "2024-01-28",
            videoLink: "https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe",
            video: getVideoInfo("https://www.youtube.com/embed/Icwdh1wnpug?si=avaU2kq2MH8ZtYLe"),
            up: 1,
            title: "회개의 기쁨을 찾아라",
            sermonType: "주일설교"
          },
          links: [{
            linkName: "test",
            link: "https://www.naver.com"
          }]
        }
      ] */

      try {
        const { statusText, data: res = {} } = await getMyGroupDashboardAPI();
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        this.dashboardList = res.result.map((itm) => {
          let videoLink = itm.sermon.videoLink;
          if (videoLink === undefined) videoLink = "";

          itm.sermon.video = getVideoInfo(videoLink);

          if (itm.sermon.up !== undefined) itm.sermon.up = Number(itm.sermon.up);
          if (itm.jubo.up !== undefined) itm.jubo.up = Number(itm.jubo.up);
          if (itm.accounts.accountCnt !== undefined) itm.accounts.accountCnt = Number(itm.accounts.accountCnt);

          return itm;
        });
      } catch (err) {
        console.error(`getMyGroupDashboard err : `, err);
      }
    },

    // dashboard update(메인 대시보드 노출/비노출)
    async updateMyGroupDashboard(payload) {
      try {
        const { statusText, data: res = {} } = await updateMyGroupDashboardAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`updateMyGroupDashboard err : `, err);
      }
    },

    // 내 교회/단체 목록
    async getMyGroupList() {
      /* this.myGroupList = [
        {
            grpCd: "447",
            grpName: "선한목자교회★",
            displayYn: "Y",
            sortNo: 1
        },
        {
            grpCd: "448",
            grpName: "목자교회★",
            displayYn: "Y",
            sortNo: 2
        },
        {
            grpCd: "449",
            grpName: "선한교회★",
            displayYn: "Y",
            sortNo: 3
        },
        {
            grpCd: "450",
            grpName: "선한목자교회",
            displayYn: "Y",
            sortNo: 4
        },
        {
            grpCd: "451",
            grpName: "목자교회",
            displayYn: "Y",
            sortNo: 5
        }
      ] */
      try {
        const { statusText, data: res = {} } = await getMyGroupListAPI();
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        this.myGroupList = res.result;
      } catch (err) {
        console.error(`getMyGroupList err : `, err);
      }
    },

    // my group update(내 교회/단체 목록 순서 저장)
    async updateMyGroupList(payload) {
      try {
        const { statusText, data: res = {} } = await updateMyGroupListAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`updateMyGroupList err : `, err);
      }
    },

    async setOrderModal(boolean) {
      this.orderModalOpen = boolean;
      this.orderModalClose = !boolean;

      if (boolean) {
        await this.getMyGroupList();
      } else {
        this.myGroupList = [];
      }

      return boolean;
    },

    async setDrag(boolean) {
      this.drag = boolean;

      if (this.drag === false) {
        // 순서 저장
        const payload = {
          grpCd: [],
        };

        this.myGroupList.map((itm) => {
          payload.grpCd.push(itm.grpCd);
        });

        payload.grpCd = payload.grpCd.toString();

        this.updateMyGroupList(payload);
      }

      return boolean;
    },

    // 동역
    async getFellowList() {
      try {
        const { statusText, data: res = {} } = await getFellowListAPI();
        if (statusText !== "OK" || res?.result === undefined) throw Error;

        return res.result;
      } catch (err) {
        console.error(`getFellowList err : `, err);
      }
    },

    // 동역 상세
    async getFellowDetail(payload) {
      try {
        const { statusText, data: res = {} } = await getFellowDetailAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;
        return res.result;
      } catch (err) {
        console.error(`getFellowDetail err : `, err);
      }
    },

    // 회원탈퇴
    async cancelMembership(payload) {
      try {
        const { statusText, data: res = {} } = await cancelMembershipAPI(payload);
        if (statusText !== "OK" || res?.result === undefined) throw Error;
        return res.result;
      } catch (err) {
        console.error(`cancelMembership err : `, err);
      }
    },

  },
});

function youtube_parser(videoUrl) {
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = videoUrl.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

function getVideoInfo(videoUrl) {
  const video = {
    url: "",
    thumb: "",
    type: "",
  };

  if (videoUrl == "") return video;

  let youtube_video_id = youtube_parser(videoUrl);

  if (youtube_video_id) {
    let video_thumbnail = "https://img.youtube.com/vi/" + youtube_video_id + "/mqdefault.jpg";
    video.url = "https://www.youtube.com/embed/" + youtube_video_id;
    video.thumb = video_thumbnail;
    video.type = "youtube";
  } else {
    let vimeo_video_url = "https://vimeo.com/api/oembed.json?url=" + videoUrl;

    fetch(vimeo_video_url)
      .then(function (response) {
        return response.json();
      })
      .then(
        function (response) {
          //console.log(response);
          video.url = "https://player.vimeo.com/video/" + response.video_id;
          video.thumb = response.thumbnail_url_with_play_button;
          video.type = "vimeo";
        },
        function (error) {
          console.log("VideoUrlError", videoUrl);
        }
      );
  }

  return video;
}
