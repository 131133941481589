import axios from "axios";
import { objToFormData } from "~/utils/utils";

// https://jwj.kr/common/code/commonCodeList.do

const axiosInstance = function (url) {
  const hostname = window.location.hostname;
  let baseURL;
  if (hostname == "localhost") {
    baseURL = url || "/common";
  } else {
    baseURL = `https://jwj.kr${url || "/common"}`;
  }

  return axios.create({
    baseURL: baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

export const getCodeListAPI = async () => {
  try {
    return await axiosInstance().get(`/code/commonCodeList.do`, { params: { upperCommonCd: `COMMONCD001000000000` } });
  } catch (err) {
    console.error(`getCodeListAPI err : `, err);
  }
};

// 아이디 중복확인, post, https://jwj.kr/common/usr/memInfoDuplicate.do
export const memInfoDuplIdAPI = async (loginId) => {
  try {
    return await axiosInstance().post(`/usr/memInfoDuplicate.do`, objToFormData(loginId));
  } catch (err) {
    console.error(`memInfoDuplIdAPI err : `, err);
  }
};

/**
 * @title [101] 공지사항 목록
 * @startNum : 시작점(기본값 0)
 * @endNum : 가져올 글 개수(기본값 20)
 */
export const getNoticeListAPI = async (payload) => {
  return await axiosInstance("/ajax").get(`/util/selectNoticeList.do`, { params: { ...payload } });
};

// 아이디 찾기
//export const certSMSsendAPI = async

/**
 * [041] 댓글 저장 API
 * @diaryCd : 해당 글의 코드
 * @boardCd : 게시판 코드(칼럼 : Board000000000000005, 일기 : Board000000000000006, 추천 : Board000000000000007)
 * @memCd : 댓글 작성자 회원코드
 * @postCnts : 댓글내용
 * @postLevel : 게시글수준(뎁스)
 * @postSortNum : 게시글정렬번호
 */

/**
 * [101-02] 투데이 공지사항 팝업 API
 */
export const getTodayNoticeAPI = async () => {
  return axiosInstance("/ajax").get(`/util/selectNoticeToday.do`);
};

/**
 * 선택한 유저 정보 가져오기
 * @param {string} memCd
 */
export const getMemInfoAPI = async (payload) => {
  return await axiosInstance("/login").post(`/selectUserInfo.do`, objToFormData(payload));
};
