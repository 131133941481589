<template>
  <NuxtLayout>
    <!-- <NuxtPage :page-key="commonStore.pageKey.app" /> -->
    <NuxtPage />
    <JwConfirmDialog />
  </NuxtLayout>
</template>

<script setup>
import JwConfirmDialog from "~/components/common/JwConfirmDialog.vue";
import { getCodeListAPI } from "./store/api/common";
import { useAuthStore } from "~/store/auth";
import { useCommonStore } from "~/store/common";

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const commonStore = useCommonStore();
//const { loginStatus: storeLoginStatus = 'pending' } = storeToRefs(authStore);

const storeLoginStatus = computed(() => authStore.loginStatus);

/* definePageMeta({
  keepalive: {
    include: ['Today', 'SectionColumn']
  }
}); */

watch(
  () => route,
  (v) => {},
  { immediate: true, deep: true }
);

const hideView = computed(() => {
  if (["/", "signin"].includes(route.path) && storeLoginStatus.value === "pending") return true;
  else return false;
});

async function getCodeListActor() {
  try {
    const { data: res = {} } = await getCodeListAPI();
  } catch (err) {
    console.error(`getCodeList err : `, err);
  }
}

onBeforeMount(() => {
  //getCodeListActor();
  //authStore.chkLoginStatusActor();
});

let intervalId;
onMounted(() => {
  if (location.hostname == "localhost") {
    intervalId = setInterval(authStore.chkLoginStatusActor, 60000);
    authStore.chkLoginStatusActor();
  }
});

onBeforeUnmount(() => {
  if (location.hostname == "localhost") {
    clearInterval(intervalId);
  }
});
</script>
