<template>
  <v-overlay v-model="showLoginBtn" persistent location-strategy="connected" scroll-strategy="reposition"
    class="align-end justify-center jw-guest-fab">
    <VBtn @click.stop="onClickToLogin" rounded="xl" size="x-large" color="#2DA562"
      class="jw-login-btn mt-8 text-white px-6 mb-8">로그인 / 회원가입</VBtn>
  </v-overlay>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import { storeToRefs } from 'pinia';
const authStore = useAuthStore();
//const { loginStatus: storeLoginStatus = false } = storeToRefs(authStore);
const loginStatus = computed(() => {
  return authStore.loginStatus;
})

const router = useRouter();

const showLoginBtn = computed(() => {
  return !loginStatus.value;
});

async function onClickToLogin() {
  try {
    return router.push('/signin?toLogin=true').then(() => true).catch((err)=>{
      console.error(`onClickToLign err :: `, err);
    })
  } catch (err) {
    console.error(`onClickToLogin err : `, err);
  }
}
</script>

<style scoped lang="scss"></style>
