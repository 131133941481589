<!-- <template>
  <v-container :fluid="!isPc" class="pa-5 pt-4" v-show="showToday">
    <AlarmBar :showSnackBar />
    <div class="jw-container--full mb-5">
      <TopBanner />
    </div>
    
    <template v-if="newList?.length > 0">
      <Suspense>
        <component v-for="(itm, idx) in newList" :is="itm.component" v-bind="itm" @loaded="onComponentLoaded" />
      </Suspense>
    </template>
    <LoginOverlay v-show="showLoginOverlay" />
    <todayNoticePop />
  </v-container>
</template> -->

<template>
  <ClientOnly>
    <v-lazy transition="fade-transition">
      <v-container :fluid="!isPc" class="pa-5 pt-4" v-show="showToday">
        <AlarmBar :showSnackBar />
        <div class="jw-container--full mb-5">
          <TopBanner />
        </div>
        <Suspense>
          <template #default>
            <div v-if="newList.length > 0">
              <component v-for="(itm, idx) in newList" :key="idx" :is="itm.component" v-bind="itm" @loaded="onComponentLoaded" />
            </div>
          </template>
          <template #fallback>
            <v-skeleton-loader type="card" />
          </template>
        </Suspense>
        <LoginOverlay v-show="showLoginOverlay" />
        <todayNoticePop />
      </v-container>
    </v-lazy>
  </ClientOnly>
</template>

<script setup>
import { useDisplay } from "vuetify";
import { useTodayStore } from "~/store/today";
import { useCommonStore } from "~/store/common";
import { useAuthStore } from "~/store/auth";

import dayjs from "dayjs";
import { isEmpty } from "~/utils/utils";
import { useNativeWebView } from "~/composables/useNativeWebview";
import { useScrollHandler } from "~/composables/useScrollHandler";
import AlarmBar from "~/components/today/AlarmBar.vue";
import LoginOverlay from "~/components/today/LoginOverlay.vue";
import TopBanner from "~/components/today/TopBanner.vue";
import todayNoticePop from "~/components/today/modal/TodayNoticeModal.vue";

const Column = defineAsyncComponent(() => import("~/components/today/Column.vue"));
const Daily = defineAsyncComponent(() => import("~/components/today/Daily.vue"));
const DiaryRecom = defineAsyncComponent(() => import("~/components/today/DiaryRecom.vue"));
const SharingRoom = defineAsyncComponent(() => import("~/components/today/SharingRoom.vue"));
const WithJTraining = defineAsyncComponent(() => import("~/components/today/WithJTraining.vue"));

definePageMeta({
  name: "Today",
  layout: "default",
  transition: "page", // 페이지 전환 트랜지션 설정
});
const { isNativeWebView } = useNativeWebView();

const router = useRouter();
const route = useRoute();
const { xlAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);
const commonStore = useCommonStore();
const todayStore = useTodayStore();
const authStore = useAuthStore();

const contentsList = computed(() => todayStore.state.contentsList);
const newTodayList = computed(() => todayStore.state.newList);
const loginStatus = computed(() => (isEmpty(authStore.loginStatus) || authStore.loginStatus == "pending" ? false : authStore.loginStatus));
const showLoginOverlay = computed(() => route.name != "signin");
let showToday = ref(false);

const compoMatcher = {
  Column: Column,
  Moment: Daily,
  Rcmd: DiaryRecom,
  Sharing: SharingRoom,
  365: WithJTraining,
};

const newMatcher = {
  todayColumn: Column,
  todayWithStory: Column,
  //todayColumn2: Column,
  todayRcmd: DiaryRecom,
  today365: WithJTraining,
  todayRoom: SharingRoom,
  todayMoment: Daily,
};

const newList = computed(() => {
  if (route.name == "Today") {
    return contentsList.value.reduce((acc, cur) => {
      if (cur.visible === "Y") {
        acc.push({ ...cur, component: newMatcher[cur.catCd] });
      }
      return acc;
    }, []);
  } else {
    return [];
  }
});
const loadedComponents = ref(0);
const totalComponents = computed(() => newTodayList.value.length);
const onComponentLoaded = () => {
  loadedComponents.value += 1;
};
const allComponentsLoaded = computed(() => loadedComponents.value === totalComponents.value);
watch(
  () => allComponentsLoaded.value,
  (newVal) => {
    if (newVal) {
      showToday.value = true;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => loadedComponents.value,
  () => {
    //console.log("로드된 컴포넌트 개수 ===> ", loadedComponents.value);
  },
  { deep: true, immediate: true }
);

watch(
  () => totalComponents.value,
  () => {
    //console.log("전체 컴포넌트 개수 ===> ", totalComponents.value);
  },
  { deep: true, immediate: true }
);

const todayList = computed(() => {
  try {
    if (route.name == "Today") {
      const adjList = contentsList.value.reduce((acc, cur) => {
        if (cur.visible === "Y") {
          acc.push({ ...cur, component: compoMatcher[cur.roomCd] || compoMatcher["Sharing"] || compoMatcher["365"] });
        }
        return acc;
      }, []);
      setTimeout(() => (showToday.value = true), 800);
      return adjList;
    } else {
      return [];
    }
  } catch (err) {
    console.error(`todayPage todayList computed err: `, err);
  }
});

const state = reactive({ bannerList: [] });
commonStore.appHeader.pageTitle = "투데이";
commonStore.appHeader.btnBack = false;

const onClickCard = (cardItem) => {};
const onClickBanner = (targetPath) => {};

async function getColumnAction() {
  const payload = {
    memCd: "100",
    siteLangCd: "COMMONCD001000000001",
    boardCd: "Board000000000000005",
    diaryTypeCd: "COMMONCD007000000002",
    yyyy: dayjs().format("YYYY"),
    mm: dayjs().format("MM"),
    startNum: "0",
  };
  await todayStore.getColumnList(payload);
}

const hrefPattern = /<a[^>]*href=['"]([^'"]*)['"][^>]*>/;
const srcPattern = /<img[^>]*src=['"](\/\/[^'"]+)['"][^>]*>/;

async function getBannerAction() {
  await todayStore.getBannerList();
}

async function getMomentAction() {
  await todayStore.getMomentList();
}

async function getRcmdAction() {
  await todayStore.getRcmdList();
}

async function onClickToLogin() {
  try {
    return router.push("/signin?toLogin=true").then(() => true);
  } catch (err) {
    console.error(`onClickToLogin err: `, err);
  }
}

let showSnackBar = ref(true);
let scrollThreshold = ref(20); // 스크롤 임계값 설정 (20px)

const { handleScroll } = useScrollHandler(showSnackBar, scrollThreshold.value);

onMounted(async () => {
  if (isNativeWebView()) {
    window.addEventListener("touchmove", handleScroll);
  } else {
    window.addEventListener("scroll", handleScroll);
  }
  await todayStore.getTodayListActor();
  await todayStore.getJWJTrainingListActor();
  await getBannerAction();

  await authStore.chkLoginStatusActor();
  await todayStore.getTodayCateListAction();

  //
  await nextTick();
  showToday.value = true;
  if (loginStatus.value == true) {
    todayStore.getTodayAlarmActor();
    commonStore.getTodayNoticeActor();
  }
});

onBeforeUnmount(() => {
  try {
    if (isNativeWebView()) {
      window.removeEventListener("touchmove", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
  } catch (err) {
    console.error(`today page unmount err: `, err);
  }
});
</script>

<style scoped>
/* 페이지 전환 애니메이션 */
.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s ease;
}
.page-enter, .page-leave-to /* .page-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
