<template>
  <!-- <swiper v-bind="defaultSwiperOption" class="jw-swiper" :autoplay="autoPlayOpt" :modules="swiperModules"> -->
  <!-- <swiper
    ref="bannerSwiper"
    v-if="!!bannerList && bannerList.length > 0"
    v-bind="defaultSwiperOption"
    class="jw-swiper"
    :modules="modules"
    :autoplay="{ delay: 5000, disableOnInteraction: false }"
    @swiper="activeSwiper"
  > -->
  <swiper
    v-if="bannerSwiper && !!bannerList && bannerList.length > 0"
    v-bind="defaultSwiperOption"
    class="jw-swiper"
    :modules="modules"
    :autoplay="{ delay: 5000, disableOnInteraction: false }"
    @swiper="activeSwiper"
    ref="bannerSwiper"
  >
    <swiper-slide ref="swiperSlides" v-for="(slide, index) in bannerList" :key="`bannerList-${index}`">
      <div class="jw-banner-card jw-banner-card--wrap" @click="onClickBanner(slide.target)">
        <VImg :src="slide.image" width="100%" aspect-ratio="100/19" cover @click.prevent.stop="onClickBanner(slide.link)">
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
            </div>
          </template>
        </VImg>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
//import { Swiper } from 'nuxt-swiper';
import { Autoplay } from "swiper/modules";
//import { Autoplay } from 'swiper';
import "swiper/css";

import { useTodayStore } from "~/store/today";
import { storeToRefs } from "pinia";
import { isEmpty } from "~/utils/utils";

import sampleImage from "~/assets/images/sample_banner.png"; // 샘플 이미지 임시로 넣었어s요
import sampleImageGuide from "~/assets/images/sample_banner_guide.png"; // 샘플 이미지 임시로 넣었어요

//sconst swiper = useSwiper();

const todayStore = useTodayStore();
//const { bannerList: storeList = [] } = storeToRefs(todayStore);
const storeList = computed(() => todayStore.state.bannerList);

const state = reactive({ swiper: null });

let bannerSwiper = ref(true);
let swiperSlides = ref();

watch(
  () => bannerSwiper.value,
  (v) => {
    //
  },
  { deep: true, immediate: true }
);

const bannerList = computed(() => {
  try {
    const list = storeList.value;
    if (isEmpty(list) || list.length === 0) return [];
    else return list;
  } catch (err) {
    console.error(`TopBanner bannerList err : `, err);
  }
});

const defaultSwiperOption = {
  slidesPerView: "auto",
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  spaceBetween: 10,
  loop: true,
};
//const swiperModules = [Autoplay];
const modules = [Autoplay];
const autoPlayOpt = {
  delay: 5000,
  disableOnInteraction: false,
};

// 배너 슬라이드 데이터
const bannerSlide = reactive([
  {
    title: "[테스트] 아주아주 긴 제목 테스트 - 새로운 봄. With Mobile",
    subtitle: "2022년 5월호 - vol 24",
    bannerImg: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 5",
  },
  {
    title: "새로운 봄. With Mobile",
    subtitle: "2022년 4월호 - vol 23",
    bannerImg: sampleImageGuide, // 샘플 이미지 임시로 넣었어요
    target: "target path 4",
  },
  {
    title: "새로운 봄. With Mobile",
    subtitle: "2022년 3월호 - vol 22",
    bannerImg: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 3",
  },
  {
    title: "새로운 봄. With Mobile",
    subtitle: "2022년 2월호 - vol 21",
    bannerImg: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 2",
  },
  {
    title: "새로운 봄. With Mobile",
    subtitle: "2022년 1월호 - vol 20",
    bannerImg: sampleImage, // 샘플 이미지 임시로 넣었어요
    target: "target path 1",
  },
]);

function onClickCard() {}

function activeSwiper(swiperObj) {
  state.swiper = swiperObj;
}

function onClickBanner(link) {
  window.open(link, "_blank");
}

onBeforeMount(() => {});

onBeforeRouteLeave(() => {
  try {
    //bannerSwiper.value = false;
  } catch (err) {
    console.error(`topBanner unmount err : `, err);
  }
});
</script>
