import {
  getMyDailyChkListAPI,
  chkTmpDiaryAPI,
  getDiaryFullInfoAPI,
  getRoomMemAPI,
  getWriteMomentListAPI,
  updateMyMomentAPI,
  insertDiaryAPI,
  updateTmpDiaryAPI,
  uploadDiaryImgAPI,
  getGuideListAPI,
  getMyGuideAPI,
  saveDailyCheckAPI,
  getTagListAPI,
  saveTagAPI,
  saveDiaryWeatherAPI,
  deleteDiaryAPI,
} from "./api/write";

import { isEmpty, isFalsy } from "~/utils/utils";

export const useWriteStore = defineStore("write", () => {
  const myDailyRoutines = reactive({});
  const myTmpDiary = reactive({});

  const state = reactive({
    tmpDiaryCd: null,
    tempSaveYn: "",
    routineList: null,
    tmpDiaryInfo: null,
    shareRoomList: null,
    momentList: null,
    shareStatus: true,
    guideList: [],
    tagList: [],
    myTagList: [],
    tagDialog: false,
    writeToggle: "KEYBOARD",
    statusText: "",
    submitBtn: false,
  });
  function routineBinding(code) {
    // 항상 computed를 반환하고, computed 내부에서 조건을 확인합니다.
    return computed(() => {
      // state.routineList?.list가 존재하면 검색을 수행하고, 그렇지 않으면 null을 반환합니다.
      return state.routineList?.list?.find((itm) => itm.orgChkCd === code) ?? { value: null };
    });
  }

  const computedReadBible = routineBinding("DailyCheck0000000001");
  const computedLookJesus = routineBinding("DailyCheck0000000004");
  const computedObey = routineBinding("DailyCheck0000000004");
  const computedPray = routineBinding("DailyCheck0000000002");
  const computedQT = routineBinding("DailyCheck0000000003");

  // 내 신앙루틴(일일체크) 목록 List 불러옴
  // 신앙 루틴은 기본값이 체크 해제이다.
  /**
   *
   * @title 내 신앙루틴(일일체크) 목록
   * @diaryCd
   */
  async function getMyDailyChkListActor({ tmpDiaryCd, initCall }) {
    try {
      const { statusText, data: res = {} } = await getMyDailyChkListAPI(tmpDiaryCd);
      if (statusText !== "OK" || res?.result === undefined) throw Error;
      //state.routineList = [...res.result];
      /* 
       inputText : 말씀읽기, 예수님바라보기, 순종하기
       checkbox : 기도, 묵상
      */

      state.routineList = {
        drag: false,
        editMode: false,
        list: [
          ...res.result.reduce((acc, cur, idx) => {
            if (cur.dailyCheckCd != "DailyCheck0000000004") {
              /* 말씀읽기, 기도, 묵상 */
              acc.push({
                id: idx,
                rename: false,
                checked: initCall ? false : cur.useYn === "Y",
                name: cur.langMssgNm.replace("비판판단금지", "기도"),
                name: cur.langMssgNm,
                myChkCd: cur.myDailyCheckCd,
                orgChkCd: cur.orgDailyCheckCd,
                value: cur.langMssgNm == "말씀읽기" ? cur.userInput1 : !!cur.regLDate,
                kind: cur.langMssgNm == "말씀읽기" ? "input" : "checkbox",
              });
            } else {
              /* 예수님바라보기, 순종하기 */
              let splitedName = cur.langMssgNm.split("@");
              splitedName.forEach((itm, idx2) => {
                acc.push({
                  //id: idx,
                  id: idx2 == 0 ? idx : idx + 1,
                  rename: false,
                  checked: initCall ? false : cur.useYn === "Y",
                  name: itm,
                  myChkCd: cur.myDailyCheckCd,
                  orgChkCd: idx2 == 0 ? cur.orgDailyCheckCd : "DailyCheck0000000005",
                  value: idx2 == 0 ? cur.userInput1 : cur.userInput2,
                  kind: "input",
                });
              });
            }
            return acc;
          }, []),
        ],
      };

      return state.routineList;
    } catch (err) {
      console.error(`getMyDailyChkListActor err : `, err);
    }
  }

  async function getDiaryFullInfoActor(payload) {
    try {
      const { statusText, data: res = {} } = await getDiaryFullInfoAPI(payload);
      if (statusText !== "OK" || res?.result === undefined) throw Error;

      //state.tmpDiaryInfo = res.result.diaryCntsFull; // 저장중인 글 html
      state.tmpDiaryCd = payload;
      state.tmpDiaryInfo = { ...res.result };
      return state.tmpDiaryInfo;
    } catch (err) {
      console.error(`getDiaryFullInfoActor err : `, err);
    }
  }

  async function getMyShareRoomListActor({ initCall, diaryCd }) {
    try {
      const { statusText, data: res = {} } = await getRoomMemAPI(!isEmpty(diaryCd) && diaryCd);

      if (statusText !== "OK" || res?.roomList === undefined) throw Error;
      state.shareRoomList = [
        ...res.roomList.map((itm) => ({
          roomName: itm.roomName,
          roomCd: itm.roomCd,
          openYn: initCall == true ? true : !isEmpty(itm.openRoomCd) ? true : false,
          joinStDate: itm.joinStDate,
        })),
      ];
      if (state.shareRoomList.filter((itm) => itm.openYn).length == 0) state.shareStatus = false;
      return state.shareRoomList;
    } catch (err) {
      console.error(`getMyShareRoomListActor err : `, err);
    }
  }

  async function getWriteMomentListActor() {
    try {
      const { statusText, data: res = {} } = await getWriteMomentListAPI({ pageMenu: "write-apply" });
      if (statusText !== "OK" || res?.result === undefined) throw Error;
      //state.momentList = [...res.result];
      state.momentList = [
        ...res.result.map((itm) => ({
          leaveActive: false,
          content: `<p>${itm.momentCnts}</p>`,
          momentCd: itm.momentCd,
          isClosed: itm.isClosed === "Y" ? "Y" : "N",
        })),
      ];

      return state.momentList;
    } catch (err) {
      console.error(`getWriteMomentListActor err : `, err);
    }
  }

  async function updateWriteMomentActor(payload) {
    try {
      const { statusText, data: res = {} } = await updateMyMomentAPI(payload);
      if (statusText !== "OK" || res?.result === undefined) throw Error;
      return res;
    } catch (err) {
      console.error(`updateWriteMomentActor err : `, err);
    }
  }

  async function chkTmpDiaryActor({ diaryTypeCd }) {
    try {
      const { statusText, data: res = {} } = await chkTmpDiaryAPI({ diaryTypeCd: !isEmpty(diaryTypeCd) ? diaryTypeCd : "" });
      if (statusText !== "OK" || res?.diary === undefined) throw Error;

      if (res.diary == null) {
        state.tmpDiaryCd = null;
        state.tmpDiaryInfo = null;
        return null;
      } else {
        if (res?.diary?.diaryCd) state.tmpDiaryCd = res.diary.diaryCd;
        state.tmpDiaryInfo = { ...res.diary };
        return state.tmpDiaryInfo;
      }

      //return res.diary;
    } catch (err) {
      console.error(`chkTmpDiaryActor err : `, err);
    }
  }

  /**
   * 이 API는 글쓰기 페이지에서 이전에 작성했던 글이 없는 경우에
   * 앞으로 사용할 diaryCd를 발급받는 용도이다.
   */
  async function insertDiaryActor(payload) {
    try {
      const { statusText, data: res = {} } = await insertDiaryAPI(!isEmpty(payload) && payload);
      if (statusText !== "OK" || res?.diaryCd === undefined) throw Error;

      state.tmpDiaryCd = res.diaryCd;
      //state.tempSaveYn = res.tempSaveYn;
      return res;
    } catch (err) {
      console.error(`insertDiaryActor err : `, err);
    }
  }

  async function saveDailyChkActor(payload) {
    try {
      const res = await saveDailyCheckAPI({ ...payload });

      return res;
    } catch (err) {
      console.error(`saveDailyChkActor err : `, err);
    }
  }

  async function updateTmpDiaryActor(payload) {
    try {
      const { statusText, data: res = {} } = await updateTmpDiaryAPI(payload);
      if (statusText !== "OK" || res?.result === undefined) throw Error;

      return res.result;
    } catch (err) {
      console.error(`updateTmpDiaryActor err : `, err);
    }
  }

  // 이미지?, OCR을 저장한다.
  /**
   *
   * @param {uploadFileSavePath} : 업로드경로 =>
   * @param {file} : 업로드 파일(바이너리)
   * @param {siteLangCd} : OCR -> 언어코드
   * @param {uploadFileType} : 업로드 파일 유형, ocr인 경우 ocr로 고정
   * @param {uploadFileNo} : ocr 파일 번호
   */
  async function uploadDiaryImgActor(payload) {
    try {
      const { statusText, data: res = {} } = await uploadDiaryImgAPI({ ...payload });
      if (statusText !== "OK" || res === undefined) throw Error;

      console.clear();

      if (payload.uploadFileType) {
        // ocr업로드인 경우
        let ocrCnts = "";
        let ocrJData = JSON.parse(res.ocrData);

        ocrJData = ocrJData.images;

        const fields = ocrJData.flatMap((item) => item.fields);
        fields.forEach((field) => {
          ocrCnts += `${field.inferText} `;
          if (field.lineBreak || field.lineBreak == "true") {
            ocrCnts += "<br>";
          }
        });
        return ocrCnts;
      } else {
        // 쓰기페이지 커버추가 업로드
        return res;
      }
    } catch (err) {
      console.error(`uploadDiaryImgActor err : `, err);
    }
  }

  async function getGuideListActor() {
    try {
      const { statusText, data: res = {} } = await getGuideListAPI();
      if (statusText !== "OK" || res === undefined) throw Error;
      state.guideList = [
        ...Object.keys(res).map((itm) => ({
          code: res[itm].code,
          name: res[itm].name,
          template: res[itm].value,
        })),
      ];

      return state.guideList;
    } catch (err) {
      console.error(`getGuideListActor err : `, err);
    }
  }

  async function getMyGuideActor() {
    const { statusText, data: res = {} } = await getMyGuideAPI();

    return res;
  }

  async function getTagActor(diaryCd) {
    try {
      const { statusText, data: res = {} } = await getTagListAPI(!isFalsy(diaryCd) && diaryCd);

      state.tagList = res.result.map((itm) => ({ ...itm, select: itm.tagSelected != "N" }));
      return state.tagList;
    } catch (err) {
      console.error(`getTagActor err : `, err);
    }
  }

  async function saveTagActor(diaryCd, tagCds) {
    try {
      const { data: res = {} } = await saveTagAPI({ diaryCd, tagCds });
      return res;
    } catch (err) {
      console.error(`saveTagActor err : `, err);
    }
  }

  async function saveDiaryWeatherActor({ diaryCd, weather }) {
    try {
      const { data: res = {} } = await saveDiaryWeatherAPI({ diaryCd, weather });
      return res;
    } catch (err) {
      console.error(`saveDiaryWeatherActor err : `, err);
    }
  }

  async function deleteDiaryActor(payload) {
    return await deleteDiaryAPI(payload);
  }

  return {
    myDailyRoutines,
    myTmpDiary,
    state,
    computedReadBible,
    computedLookJesus,
    computedObey,
    computedPray,
    computedQT,
    getMyDailyChkListActor,
    chkTmpDiaryActor,
    getDiaryFullInfoActor,
    getMyShareRoomListActor,
    getWriteMomentListActor,
    updateWriteMomentActor,
    insertDiaryActor,
    saveDailyChkActor,
    updateTmpDiaryActor,
    uploadDiaryImgActor,
    getGuideListActor,
    getMyGuideActor,
    getTagActor,
    saveTagActor,
    saveDiaryWeatherActor,
    deleteDiaryActor,
  };
});
