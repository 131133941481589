import axios from "axios";
import { objToFormData, isEmpty } from "~/utils/utils";

//  https://jwj.kr/api/selectBannerList.do

const axiosInstance = function (url) {
  const hostname = window.location.hostname;
  let baseURL;
  if (hostname == "localhost") {
    baseURL = url || "/api";
  } else {
    baseURL = `https://jwj.kr${url || "/api"}`;
  }

  return axios.create({
    baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

/**
 * @title [051] 내 일일체크(신앙루틴) 목록 API
 * @diaryCd : 일기코드
 * @regLDate : 일기 작성일
 * @memCd : 로그인한 회원 코드(세션으로 처리?)
 */
export const getMyDailyChkListAPI = async (payload) => {
  return await axiosInstance("/ajax").get(`/home/selectMyDailyCheckRslt.do`, { params: { diaryCd: payload } });
};

/**
 * @title [052] 내 나눔방 목록 API
 */
export const getRoomMemAPI = async (diaryCd) => {
  return await axiosInstance("/ajax").get("/room/selectRoomMem.do", { params: { ...(!isEmpty(diaryCd) && { diaryCd }) } });
};

/**
 * @title [053] 임시저장 일기 체크 API
 * @diaryTypeCd : 일기유형코드 ex) COMMONCD007000000001
 */
export const chkTmpDiaryAPI = async ({ diaryTypeCd }) => {
  return await axiosInstance("/ajax").get(`/home/tmpDiaryCheckDupl.do`, {
    params: { diaryTypeCd: !isEmpty(diaryTypeCd) ? diaryTypeCd : `COMMONCD007000000001` },
  });
};

/**
 * @title [054] 임시저장 중인 일기 정보 API
 * @diaryCd : 일기코드
 */
export const getDiaryFullInfoAPI = async (diaryCd) => {
  return await axiosInstance("/ajax").get("/diary/selectDiaryFullInfo.do", { params: { diaryCd } });
};

/**
 * @title [056] 일일체크(신앙루틴) 저장 API
 * @diaryCd : 일기코드
 * @regLDate
 * @tempSaveYn : 임시저장여부(Y, N)
 * @gubun : (Y,N)
 * @myCheckCdStr : 일일체크 신앙루틴 값(구분자 : $)
 */
export const saveDailyCheckAPI = async (payload) => {
  //return await axiosInstance('/ajax').get('/util/dailyCheckRsltProc.do', { params: { ...payload } });
  return await axiosInstance("/ajax").post("/util/dailyCheckRsltProc.do", objToFormData({ ...payload }));
};

/**
 * @title : [059] 이미지 저장 API
 * @uploadFileSavePath : 업로드 경로(diary)
 * @file : 업로드 파일(바이너리)
 * @siteLangCd : OCR -> 언어코드
 * @uploadFileType : OCR -> 업로드파일 유형(ocr)
 * @uploadFileNo : OCR -> 파일 번호
 */
export const uploadDiaryImgAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/diary/uploadDairyImageProc.do", objToFormData({ ...payload }));
};

/**
 * @title [060] 내 메모(일상동행) 목록 API
 * @siteLangCd : 언어코드(비필수)
 * @pageMenu : 메뉴코드(write-apply) => 스트링값?
 */
export const getWriteMomentListAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/util/selectMomentList.do", { params: { ...payload } });
};

/**
 * @title [061] 메모 적용 변경 API
 * @momentCd : 일상동행(메모) 코드
 * @isClosed : 사용종료 여부 (종료면 Y)
 */
export const updateMyMomentAPI = async (payload) => {
  return await axiosInstance(`/ajax`).post(`/util/updateMyMoment.do`, objToFormData({ ...payload }));
};

/**
 * @title [062] 일기 저장 API
 * @diaryCd : 일기코드
 * @diaryTitle : 일기 제목
 * @diaryCnts : 일기 내용
 * @diaryCntsCut : 일기 내용 요약
 * @diaryEditLDate : 일기수정로컬시간
 * @diaryEditSDate : 일기수정서버시간
 * @siteLangCd : 언어코드
 * @memCd : 작성자 회원 코드
 * @roomOpenYn : 나눔방공개 여부
 * @roomList : 공개나눔방 목록
 * @diarySortDate : 일기정렬시간
 * @diarySaveLDate : 일기저장로컬시간
 * @ISortYn : 작성일자 변경여부
 * @tempSaveYn : 임시저장여부
 * @diaryTypeCd : 일기유형 코드
 * @gubun : 구분(insert)
 */
export const insertDiaryAPI = async (payload) => {
  return await axiosInstance("/common").post(`/diary/insertDiary.do`, !isEmpty(payload) && objToFormData({ ...payload }));
};

/**
 * @title [063] 일기 임시 저장(업데이트) API
 * @diaryCd
 * @diaryTitle
 * @diaryCnts : 일기 내용(p태그로 감싸진 html)
 * @diaryCntsCut
 * @diaryEditLDate
 * @diaryEditSDate
 * @siteLangCd
 * @writeGuideCd
 * @memCd
 * @roomOpenYn
 * @roomList : ===>
 * @diarySortDate
 * @diarySaveLDate
 * @ISortYn
 * @tempSaveYn
 * @diaryTypeCd
 * @gubun : 'edit'
 * @editDiaryCd
 * @uploadFileCd
 */
export const updateTmpDiaryAPI = async (payload) => {
  return await axiosInstance("/common").post("/diary/updateDiaryProcNS.do", objToFormData({ ...payload }));
};

/**
 * [055] 내가 만든 가이드 조회 API
 * @params : writeGuideCD: WriteGuide0000000005
 */
export const getMyGuideAPI = async (code) => {
  return axiosInstance(`/common`).get(`/diary/selectDefaultGuide.do`, { params: { writeGuideCD: !isEmpty(code) ? code : "WriteGuide0000000005" } });
};

/**
 * [064] 가이드 목록 API
 */
export const getGuideListAPI = async () => {
  return axiosInstance(`/ajax`).get(`/write/selectGuideList.do`);
};

/**
 * [065] 태그 목록 API
 * @params : diaryCd(일기코드, 비필수)
 */
export const getTagListAPI = async (diaryCd) => {
  return axiosInstance(`/ajax`).get(`/write/selectTagList.do`, { params: { ...(!isEmpty(diaryCd) && diaryCd != false && { diaryCd }) } });
};

/**
 * [066] 태그 등록 API
 * @params : tagName(태그명, 필수)
 */
export const addTagAPI = async (tagName) => {
  return axiosInstance(`/ajax`).post(`/write/saveTag.do`, objToFormData({ tagName }));
};

/**
 * [067] 태그 삭제 API
 * @params : tagCd(태그코드, 필수)
 */
export const delTagAPI = async (tagCd) => {
  return axiosInstance(`/ajax`).post(`/write/deleteTag.do`, objToFormData({ tagCd }));
};

/**
 * [068] 태그 저장
 * @diaryCd {string} : 일기코드
 * @tagCds {string} : 태그코드(콤마로 구분)
 *
 */
export const saveTagAPI = async (payload) => {
  return axiosInstance(`/ajax`).post("/write/saveDiaryTags.do", objToFormData({ ...payload }));
};

/**
 * [069] 일기 날씨 저장 API
 * @diaryCd {string} : 일기코드
 * @weather {string} : 날씨 스트링값(여기서 입력한 값 그대로 나중에 리턴됨)
 */
export const saveDiaryWeatherAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/write/saveDiaryWeather.do`, objToFormData({ ...payload }));
};

/**
 * 일기 삭제
 * diaryCd: 20240513192813810436
   diaryEditSDate: 20240517114308
   diaryEditLDate: 20240517114308
   delYn: Y
   diaryTypeCd: COMMONCD007000000001
   tempSaveYn: N
   memCd: 20221227101711222803
   siteLangCd: COMMONCD001000000001
   editDiaryCd: 20240513192813810436
   gubun: delete
 */
export const deleteDiaryAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/diary/updateDiaryProc.do`, objToFormData({ ...payload }));
};
