<template>
  <v-lazy transition="fade-transition">
    <Suspense>
      <VApp>
        <!-- <JwGnbHeader v-if="isPc && $route.path != '/join'" /> -->
        <JwGnbHeader v-if="showGnbHeader" />
        <PageHeader></PageHeader>
        <VMain>
          <slot />
        </VMain>
        <JwBottomNav v-if="showMainNav" />
        <JwFooter v-if="showMainFooter" />
      </VApp>
    </Suspense>
  </v-lazy>
</template>
<script setup>
import JwGnbHeader from "@/components/common/JwGnbHeader.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import JwBottomNav from "@/components/common/JwBottomNav.vue";
import JwFooter from "@/components/common/JwFooter.vue";
import { useCommonStore } from "~/store/common";
import { useDisplay } from "vuetify";

definePageMeta({
  layoutTransition: {
    name: "fade",
    mode: "out-in",
  },
});

if (process.client) {
  document.body.className = "";
}

const commonStore = useCommonStore();
const route = useRoute();
const showMainNav = computed(() => {
  return !["section-id", "shareDetail", "join"].includes(route.name) && !isPc.value;
});

const showGnbHeader = computed(() => {
  if (["/join", "/join/"].includes(route.path)) return false;
  if (isPc.value) return true;
  return false;
});

const { xlAndUp, lgAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);

watch(
  () => route.fullPath, // route.fullPath 또는 route.path를 감시합니다.
  (newPath, oldPath) => {
    commonStore.appHeader.bottomNav = !["section-id", "shareDetail"].includes(route.name);
  },
  { immediate: true }
);

const showMainFooter = computed(() => {
  if (["section-id", "shareDetail"].includes(route.name)) {
    return false;
  } else {
    return isPc.value ? true : false;
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
