<template>
  <VBottomNavigation v-if="commonStore.appHeader.bottomNav" horizontal height="75">
    <!-- <VBtn
      v-for="(menu, index) in gnbMenuList"
      :key="'bNav-' + index"
      :class="{
        active: route.path.includes(menu.path),
        avatar: menu.name === '다이어리'
      }"
      :to="menu.path"
    > -->
    <VBtn
      v-for="(menu, index) in gnbMenuList"
      :ripple="false"
      :key="'bNav-' + index"
      :class="{
        active: route.path.includes(menu.path),
        avatar: menu.name === '다이어리',
      }"
      @click.stop="onClickPaging(menu)"
    >
      <v-avatar
        v-if="menu.name === '다이어리'"
        size="33"
        class="mt-n2"
        :color="!profileImg ? 'secondary' : undefined"
        :class="{ default: !profileImg, active: route.path.includes(menu.path) }"
      >
        <VImg v-if="profileImg" :src="profileImg" />
        <VIcon v-else size="15" color="white">{{ menu.icon }}</VIcon>
      </v-avatar>
      <v-badge
        v-else-if="menu.name === '더보기' && !isEmpty(userInfo.badgeCnt) && Number(userInfo.badgeCnt) != 0"
        :content="Number(userInfo.badgeCnt)"
        offset-x="-2"
        offset-y="4"
        floating
        color="#FF3D00"
      >
        <VIcon size="24">{{ menu.icon }}</VIcon>
      </v-badge>
      <VIcon v-else size="24">{{ menu.icon }}</VIcon>
      <span v-if="menu.name !== '다이어리'" class="text">{{ $t(menu.langName) }}</span>
    </VBtn>
  </VBottomNavigation>
</template>
<script setup>
import { useAuthStore } from "~/store/auth";
import { useCommonStore } from "~/store/common";
import { isEmpty } from "~/utils/utils";

import defaultUser from "~/assets/images/user-logout.svg"; // 디폴트 이미지

const router = useRouter();
const authStore = useAuthStore();
const commonStore = useCommonStore();
//const { loginStatus } = storeToRefs(authStore);
const loginStatus = computed(() => {
  return authStore.loginStatus;
});

const bottomNav = computed(() => commonStore.appHeader.bottomNav);
//console.log("bottomNav.value ::: ", bottomNav.value)

const userInfo = computed(() => authStore.userInfo);
const profileImg = computed(() => {
  if (!isEmpty(userInfo.value.profileId)) {
    return `https://jwj.kr/upload/member/${userInfo.value.profileId}`;
  } else {
    // const imageModule = import.meta.glob('~/assets/images/user-logout.svg');
    return false;
  }
});
watch(
  () => userInfo.value,
  (itm) => {},
  { deep: true, immediate: true }
);

watch(
  () => loginStatus.value,
  () => {}
);

watch(
  () => profileImg.value,
  () => {}
);

//const { appHeader } = useCommonStore();
const route = useRoute();
// gnb 관련
const gnbMenuList = reactive([
  {
    name: "투데이",
    langName: "dwj.menu.usr.001",
    icon: "jwIcons:svg/menu-today",
    newCount: 1,
    //path: '/pub/today'
    path: "/today",
  },
  {
    name: "쓰기",
    langName: "dwj.menu.usr.002",
    icon: "jwIcons:svg/menu-write",
    newCount: 0,
    path: "/write",
  },
  {
    name: "다이어리",
    langName: "dwj.menu.usr.003",
    //icon: 'https://i.pravatar.cc/300',
    //icon: `https://jwj.kr/upload/member/${userInfo.value.profileId}`,
    icon: profileImg.value || "jwIcons:svg/user-solid",
    newCount: 2,
    path: "/diary",
  },
  {
    name: "나눔방",
    langName: "dwj.menu.usr.004",
    icon: "jwIcons:svg/menu-room",
    newCount: 2,
    //path: '/pub/room'
    path: "/room",
  },
  {
    name: "더보기",
    langName: "dwj.com.btn.018",
    icon: "jwIcons:svg/menu-more",
    newCount: 12,
    path: "/more",
  },
]);

function removeLeadingSlashUsingRegex(str) {
  return str.replace(/^\//, "");
}
function onClickPaging(menu) {
  if (menu.path == route.path) {
    //commonStore.refreshPageActor(removeLeadingSlashUsingRegex(menu.path));
    //commonStore.setPageKey("app");
    return false;
  }

  if (loginStatus.value != true && menu.name == "더보기") {
    return router.push("/signin?toLogin=true");
  }

  if (loginStatus.value !== true && ["투데이"].includes(menu.name)) {
    router.push(menu.path);
  } else if (loginStatus.value === true) {
    router.push(menu.path);
  }
}
</script>
