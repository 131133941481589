<template>
  <v-app-bar fixed class="jw-app-header">
    <template v-slot:prepend>
      <v-btn v-if="appHeader.btnBack" variant="text" icon="jwIcons:svg/arrow-left" size="24" :ripple="false" @click="onClickBack"></v-btn>
    </template>

    <!-- <v-app-bar-title v-if="appHeader.pageAuthor" class="text-center pr-12">
      <JwAuthor v-bind="appHeader" size="28" /> -->
    <v-app-bar-title v-if="appHeader.pageAuthor" class="text-center justify-center pr-12">
      <!-- <JwAuthor v-bind="appHeader.author" size="28" /> -->
      <JwAuthor v-bind="appHeader.author" :isHeader="true" size="28" :showLike="false" />
    </v-app-bar-title>
    <v-app-bar-title v-else :class="{ 'text-center': isTabletUp }" class="pl-2">{{ appHeader.pageTitle || "" }}</v-app-bar-title>
    <v-spacer v-if="!isTabletUp"></v-spacer>
    <!-- 투데이 위치조정 버튼 -->
    <v-btn data-text="투데이" v-if="route.name === 'Today' && loginStatus == true" variant="text" icon @click="todayStore.setOrderModal(true)">
      <v-icon>jwIcons:svg/sliders</v-icon>
    </v-btn>
    <v-btn v-if="appHeader.btnClose" variant="text" icon @click="onClickClose">
      <v-icon>jwIcons:svg/close</v-icon>
    </v-btn>
  </v-app-bar>

  <today-adj-modal />
</template>

<script setup>
import JwAuthor from "@/components/common/JwAuthor.vue";
import TodayAdjModal from "@/components/today/modal/TodayAdjModal.vue";

import { useDisplay } from "vuetify";
import { useCommonStore } from "~/store/common";
import { useTodayStore } from "~/store/today";
import { useAuthStore } from "~/store/auth";
import { useDiaryStore } from "~/store/diary";

const { xlAndUp, lgAndUp } = useDisplay();
//const isPc = computed(() => xlAndUp.value);
const isTabletUp = computed(() => lgAndUp.value);

defineProps({});
const store = useCommonStore();
const todayStore = useTodayStore();
const authStore = useAuthStore();
const diaryStore = useDiaryStore();
//const { appHeader: storeHeader = {} } = storeToRefs(store);
const storeHeader = computed(() => store.appHeader);

watch(
  () => storeHeader.value,
  () => {
    //console.log("storeHeader changed", storeHeader.value);
  },
  { deep: true, immediate: true }
);

const loginStatus = computed(() => authStore.loginStatus);
//const { columnDet, diaryDet } = storeToRefs(todayStore);
const columnDet = computed(() => todayStore.state.columnDet);
const diaryDet = computed(() => todayStore.state.diaryDet);
const route = useRoute();
const router = useRouter();

const appHeader = computed(() => {
  //if (route.params.section === "column") return { ...storeHeader.value };
  if (route.params.section === "column") {
    return store.appHeader;
  } else if (route.params.section === "rcmd" || route.name === "shareDetail" || route.name == "diary-diaryCd") {
    //let obj = { ...storeHeader.value, ...diaryDet.value };
    let obj = { ...diaryDet.value, ...storeHeader.value };
    obj.author.emotion = diaryDet.value.weather || "";
    return obj;
  } else if (route.name == "bookmark-view-bMCd") {
    return { ...diaryStore.state.bookMarkInfo, btnBack: true };
  }

  return storeHeader.value;
});

/* store.$subscribe((mutation, state) => {
  //appHeader = { ...state.appHeader };
  storeHeader = { ...state.appHeader };
}); */

function onClickBack() {
  // 우선 임시로 라우터 히스토리백으로 했습니다.
  router.back();
}
function onClickClose() {
  // 우선 임시로 라우터 히스토리백으로 했습니다.
  router.back();
}
</script>
