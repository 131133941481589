<template>
  <div class="diary__contents">
    <h3 class="title">{{ props.diaryTitle }}</h3>
    <p>
      {{ nbspActor(props.diaryCnts) }}
    </p>
    <div class="desc">
      <div class="comment">
        <VIcon size="18" color="#C4C4C4" class="mr-1">jwIcons:svg/comment</VIcon>
        {{ props.postCount }}
      </div>
      <div class="like ml-1">
        <VIcon size="18" color="#C4C4C4" class="mr-1">jwIcons:svg/heart</VIcon>
        {{ props.amenCnt }}
      </div>
      <div class="category">{{ props.tags }}</div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  diaryTitle: String,
  diaryCnts: String,
  postCount: [String, Number],
  amenCnt: [String, Number],
  tags: String,
});

function nbspActor(string) {
  return string.replace(/&nbsp;/g, " ");
}
</script>

<style scoped lang="scss"></style>
