<template>
  <v-dialog
    v-model="todayStore.state.orderModalOpen"
    persistent
    :fullscreen="isMobile"
    :max-width="isMobile ? undefined : 540"
    min-height="480px"
    scrollable
  >
    <v-card color="background" class="jw-dialog">
      <v-toolbar dark color="background" class="jw-dialog__header">
        <v-toolbar-items></v-toolbar-items>
        <v-toolbar-title class="today-adj"> 투데이 위치 조정 </v-toolbar-title>
        <v-toolbar-items>
          <v-btn icon="jwIcons:svg/close" @click="todayStore.setOrderModal(false)"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <VCardText class="jw-dialog__body pa-5 pt-4">
        <draggable
          tag="ul"
          :list="todayStore.state.contentsList"
          class="jw-order-list jw-order-list--wrap pt-4"
          drag-class="dragging"
          ghost-class="ghost"
          handle=".handle"
          item-key="name"
          @start="todayStore.setDrag(true)"
          @end="todayStore.setDrag(false)"
        >
          <template #item="{ element, index }">
            <li class="jw-order-list__item" :class="element.visible === 'N' && 'hidden'">
              <div class="handle">
                <v-icon>jwIcons:svg/grap-line</v-icon>
              </div>
              <div class="title">{{ $t(`${element.catCd}.title`) }}</div>
              <v-spacer />
              <!-- <div class="category">{{ $t(element.category) }}</div> -->
              <div class="category">{{ $t(`${element.catCd}.cate`) }}</div>
              <!-- <v-btn icon size="small" class="visible" @click="element.visible = !element.visible"> -->
              <v-btn icon size="small" class="visible" @click="onClickSetVisible(element)">
                <v-icon v-if="element.visible === 'Y'">jwIcons:svg/eye</v-icon>
                <v-icon v-else>jwIcons:svg/eye-off</v-icon>
              </v-btn>
            </li>
          </template>
        </draggable>
      </VCardText>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useTodayStore } from "~/store/today";
import draggable from "vuedraggable";
import { useDisplay } from "vuetify";
const { smAndDown, xlAndUp } = useDisplay();

const isMobile = computed(() => smAndDown.value);

const todayStore = useTodayStore();
//const { orderModalOpen = false, drag = false, contentsList = [] } = storeToRefs(todayStore);

watch(
  () => todayStore.state.contentsList,
  () => {},
  { deep: true, immediate: true }
);

function BooleanActor(value) {
  if (value === "Y") return "N";
  else return "Y";
}

async function onClickSetVisible(element) {
  try {
    console.log("element ===> ", element);

    const { catCd: roomCd, visible } = element;
    /**
     * roomCd(해당 섹션 코드), homeSetYn(노출여부 Y/N)
     */
    //const res = await setDisclosureStatusAction();
    const result = await todayStore.setDisclosureStatusAction({ roomCd, homeSetYn: BooleanActor(visible) });

    todayStore.state.contentsList.forEach((itm) => {
      if (itm.catCd === roomCd) itm.visible = BooleanActor(itm.visible);
    });
    await todayStore.getTodayListActor();
    return element;
  } catch (err) {
    console.error(`onClickSetVisible err : `, err);
  }
}
</script>
