import axios from "axios";
import { objToFormData, isEmpty } from "~/utils/utils";

//  https://jwj.kr/api/selectBannerList.do

const axiosInstance = function (url) {
  const hostname = window.location.hostname;
  let baseURL;
  if (hostname == "localhost") {
    baseURL = url || "/api";
  } else {
    baseURL = `https://jwj.kr${url || "/api"}`;
  }

  return axios.create({
    baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

// 최근 오픈칼럼/추천일기 목록 조회
export const getColumnRcmdListAPI = async () => {
  try {
    return await axiosInstance().get(`/login/selectColumnRcmdDiaryList.do`);
  } catch (err) {
    //console.error(`getColumnRcmdListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [003] 배너 목록 API
 * */
export const getBannerListAPI = async () => {
  try {
    return await axiosInstance().get(`/util/selectBannerList.do`);
  } catch (err) {
    console.error(`getDailyListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [004] 칼럼 목록 API
 * @memCd : 칼럼 작성자코드, 100
 * @siteLangCd : 언어코드, COMMONCD001000000001
 * @boardCd : 게시판코드, Board000000000000005
 * @diaryTypeCd : COMMONCD007000000002,
 * @yyyy : 연도
 * @mm : 달
 * @startNum : 0
 * */
export const getColumnListAPI = async (payload) => {
  try {
    return await axiosInstance().post(`/login/selectColumnList.do`, objToFormData(payload));
  } catch (err) {
    console.error(`getColumnListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [005] 일상동행 목록 API
 *  */
export const getMomentListAPI = async (payload) => {
  return await axiosInstance().get(`/login/selectMomentList.do`, { params: { ...payload } });
};

/**
 * @title [006] 추천일기 목록 API
 */
export const getRcmdListAPI = async (payload) => {
  return await axiosInstance().get(`/login/selectRcmdDiaryList.do`, { params: { ...payload } });
};

/**
 * @title [007] 칼럼 상세보기 API
 *  */
export const getColumnDetAPI = async (code) => {
  return await axiosInstance().get(`/login/selectOpenColumnDet.do`, { params: { diaryCd: code } });
};

/**
 * @title [008] 댓글 목록 API
 * @diaryCd : 게시글 종류 코드
 * @boardCd : 해당 글의 고유 코드
 */
export const getCommentListAPI = async ({ diaryCd, boardCd, roomCd = null }) => {
  try {
    return await axiosInstance().get(`/login/selectCommentList.do`, { params: { diaryCd, boardCd, ...(!isEmpty(roomCd) && { roomCd }) } });
  } catch (err) {
    console.error(`getCommentListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [041] 댓글 저장 API
 * diaryCd, boardCd(칼럼 : Board000000000000005, 일기 : Board000000000000006, 추천 : Board000000000000007)
 * memCd, postCnts
 * postLevel, postSortNum, regLDate
 */
export const insertCmtAPI = async (payload) => {
  return await axiosInstance("/ajax").post(`/util/insertPostProc.do`, objToFormData(payload));
};

/**
 * @title [042] 댓글 수정/삭제 API
 * postCd : 댓글 코드
 * postCnts : 댓글 내용(수정 시)
 * delYn : 삭제 여부 (삭제 시 Y)
 */
export const updateCmtAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/util/updatePostProc.do", objToFormData(payload));
};

/**
 * @title [unknown] 나눔방 일기 댓글목록 API
 * @roomCd : 나눔방 번호
 * @diaryCd : 일기 상세 번호
 * @boardCd : Board000000000000006 -> 고정값
 */
export const getShareRoomCmtListAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/util/selectColumnCommentList.do", objToFormData(payload));
  } catch (err) {
    throw err;
  }
};

/**
 * @title [009] 일기 상세보기 API
 */
export const getDiaryDetAPI = async ({ diaryCd, boardCd }) => {
  try {
    return await axiosInstance().get(`/login/selectDiaryDet.do`, { params: { diaryCd, boardCd } });
  } catch (err) {
    console.error(`getDiaryDetAPI err : `, err);
    throw err;
  }
};

/**
 * @title [023] 투데이 컨텐츠(내 나눔방) 목록 API
 * @orderBy : 정렬순서
 */
export const getRoomListAPI = async (orderBy) => {
  try {
    //const params = { ...orderBy, memCd: '20221227101711222803', siteLangCd: 'COMMONCD001000000001' };
    //return await axiosInstance('/ajax').post('/room/selectRoomListOnlyRoom.do', objToFormData(params));
    return await axiosInstance("/ajax").get("/room/selectRoomListOnlyRoom.do", { params: orderBy });
  } catch (err) {
    console.error(`getRoomListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [026] 투데이 내 나눔방 목록 API
 * @roomCd : 나눔방 코드
 * @openRoomCd : 나눔방 코드
 * @homeSetYn : 투데이 노출여부(Y/N)
 */
export const getMyRoomListAPI = async () => {
  try {
    return await axiosInstance("/ajax").post(`/room/selectMyRoomList.do`);
  } catch (err) {
    console.error(`getMyRoomListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [027] 투데이 내 나눔방별 일기 목록 API
 * @roomCd : 나눔방 코드
 * @startNum : 시작번호(기본값, 0)
 */
export const getMyRoomDiaryListAPI = async ({ roomCd, startNum }) => {
  try {
    return await axiosInstance("/ajax").get("/room/selectRoomOpenDiaryList.do", { params: { roomCd, startNum } });
  } catch (err) {
    console.error(`getMyRoomDiaryListAPI err : `, err);
    throw err;
  }
};

/**
 * @title [023] 투데이 컨텐츠(나눔방 포함) 목록 API
 * @retruns {visible, catCd, catName, catNo, homeSetYn}
 */
export const getTodayCateListAPI = async () => {
  return await axiosInstance("/api").get("/today/selectTodayCategoryList.do");
  //return await axiosInstance("/ajax").get("/today/selectTodayCategoryList.do");
};

/**
 * @title [024] 투데이 컨텐츠 공개/비공개 저장 API
 * @roomCd : 나눔방 코드
 * @openRoomCd : 나눔방 코드 -> 안 쓴다.
 * @homeSetYn : 투데이 노출여부(Y/N)
 */
export const setDisclosureStatusAPI = async ({ roomCd, homeSetYn }) => {
  return await axiosInstance("/ajax").get("/room/updateRoomMemHomeSetOne.do", { params: { roomCd, homeSetYn } });
};

/**
 * @title [025] 투데이 컨텐츠(내 나눔방) 순서 저장 API
 * @json Array 형식으로 전송
 *   ==> [
 *         {"visible": "Y", "category": "Column", "code": "100"},
 *         {"visible": "Y", "category": "Moment", "code": ""},
 *         {"visible": "Y", "category": "Rcmd", "code": ""},
 *         {"visible": "N", "category": "365", "code": ""},
 *         {"visible": "Y", "category": "Sharing", "code": "20230130161234465036"}
 *         :
 *         :
 *         ]
 * @visible : 공개여부('Y','N')
 * @category : 섹션명(Column, Rcmd, Moment, 365, Sharing)
 * @code : 나눔방일 경우에만, 나눔방 코드 roomCd값
 */
export const saveTodayCateListAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/today/saveTodayCategoryList.do", { params: { code: encodeURI(payload) } });
};

/**
 * @title [028] 일상동행 저장 API
 * @momentCnts : 동행일기메모 내용
 * @regLDate : 로컬 작성일시(YYYYMMDDHHMMSS)
 * @siteLangCd : 작성자 언어코드
 */

export const insertMomentProcAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post(`/util/insertMomentProc.do`, objToFormData(payload));
  } catch (err) {
    console.error(`insertMomentProcAPI err : `, err);
    throw err;
  }
};

/**
 * @params {string} momentCd : 동행메모 코드(diaryCd로 넘어오는 것을 키 이름 바꿔서 전송)
 * @params {string} memCd : 작성자 회원코드
 * @returns {Promise} result
 */
export const deleteMomentProcAPI = async (payload) => {
  return await axiosInstance("/ajax").post(`/util/deleteMomentProc.do`, objToFormData(payload));
};

/**
 * @params {string} momentCd
 * @params {string} memCd
 * @params {string} momentCnts
 * @returns
 */
export const updateMomentProcAPI = async (payload) => {
  return await axiosInstance("/ajax").post(`/util/updateMomentProc.do`, objToFormData(payload));
};

/**
 * [050] 예수동행훈련 목록 API
 *
 */
export const getJWJTrainingListAPI = async () => {
  return await axiosInstance("/wp-json").get("/wplms/v1/course/filters");
};

/**
 * 투데이 동행메모 임시저장
 * @param {string} momentCnts
 */
export const tmpSaveMemoAPI = async (payload) => {
  return await axiosInstance("/ajax").post(`/util/saveMomentTemporary.do`, objToFormData(payload));
};

/**
 * 임시저장된 동행메모 조회
 * @returns {Promise} momentCnts
 */
export const getTmpMemoAPI = async () => {
  return await axiosInstance("/ajax").get(`/util/selectMomentTemporary.do`);
};

/**
 * 투데이 상단 알림 API
 */
export const getTodayAlarmAPI = async () => {
  return await axiosInstance("/ajax").get(`/util/selectTodayAlarmList.do`);
};

export const getTodayListAPI = async () => {
  return await axiosInstance("/api").get(`/today/selectTodayDataList.do`);
  //return axios.get("http://49.254.87.49:8084/api/today/selectTodayDataList.do");
};

export const todayAlarmResAPI = async (payload) => {
  return await axiosInstance("/ajax").post(`/util/insertAlarmProc.do`, objToFormData(payload));
};
