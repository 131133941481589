import axios from "axios";
import { objToFormData, isEmpty } from "~/utils/utils";

const axiosInstance = function (base) {
  const hostname = window.location.hostname;
  let baseURL;
  if (hostname == "localhost") {
    baseURL = base || "/api";
  } else {
    baseURL = `https://jwj.kr${base || "/api"}`;
  }

  return axios.create({
    baseURL: baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

/**
 * @title [038] 칼럼,일기,댓글 차단하기 API
 * @diaryCd : 일기 코드
 * @gubun : 구분(칼럼 : t_column, 일기 : t_diary, 댓글 : t_post)
 */
export const blockingPostAPI = async (payload) => {
  return await axiosInstance("/ajax").post(`/diary/informPostProc.do`, objToFormData(payload));
};

/**
 * @title [039] 칼럼,일기,댓글 차단 해제 API
 * @diaryCd : 일기 코드
 * @gubun : 칼럼 t_column, 일기 t_diary, 댓글 t_post
 */
export const clearBlockingAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/diary/clearInformBlocking.do", objToFormData(payload));
};

/**
 * @title [040] 아멘 저장 API
 * @diaryCd : 일기코드
 * @gubun : (Y: 아멘, N: 아멘삭제)
 * @amenCount : 아멘 카운트(기본값 1로 넣는다.)
 */
export const setAmenAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/diary/updateDiaryWithAmenProc.do", objToFormData(payload));
};

/**
 * [044] 칼럼 아멘 API
 * @diaryCd  : 칼럼코드
 * @delYn : N(아멘), Y(아멘삭제)
 */
export const setColumnAmenAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/diary/updateColumnWithAmen.do", objToFormData(payload));
};

/**
 * @title 아멘 확인 API
 * @diaryCd : 해당 글 코드
 */
export const getDiaryAmenInfoAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/diary/selectDiaryAmenInfo.do", objToFormData(payload));
};

/**
 * [043-01] 칼럼 아멘 확인 API
 *
 */
export const getColumnAmenInfoAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/diary/selectColumnAmenInfo.do", objToFormData(payload));
};

/**
 * @title [034] 북마크 저장 API
 * @diaryCd : 일기코드
 * @bMType : 북마크 구분(room, diary, column)
 * @bMCd : 북마크 코드
 * @wrtrCd : 일기/칼럼 작성자 회원코드
 */
export const saveBookMarkAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/util/insertBookMarkProc.do", objToFormData(payload));
};

/**
 * @title [035] 북마크 삭제 API
 * @diaryCd : 일기코드
 * @bMType : 북마크 구분(room, diary, column)
 * @bMCd : 북마크 코드
 * @bMDate : 북마크 일시(YYYYMMDDHHmmss)
 * @wrtrCd : 일기/칼럼 작성자 회원코드
 */
export const delBookMarkAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/util/deleteBookMarkProc.do", objToFormData(payload));
};

/**
 * @title [074-03] 북마크 목록 API
 * @memCd: 회원코드
 * @bMType : 북마크 타입(column, rcmd, room, diary)
 * @siteLangCd : 언어코드
 */
export const getBookMarkListAPI = async (payload) => {
  return await axiosInstance("/ajax").get("/util/selectBookMarkInfo.do", { params: { ...payload } });
};

/**
 * @bMCd : 북마크 코드
 * @memCd : 회원코드
 * @siteLangCd : 언어코드
 */
export const getBookMarkInfoAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/util/selectBookMarkInfo.do", objToFormData(payload));
};

/**
 * @title [046] 일기 추천 API
 * @diaryCd : 일기코드
 * @rcmdMemCd : 내 회원코드
 * @memCd : 일기 작성자의 회원코드
 * @siteLangCd : 언어코드
 * @diaryRcmdLDate : 추천일시 (YYYYMMDDHHmmss)
 * @roomCd : 나눔방 코드
 */
export const rcmdDiaryAPI = async (payload) => {
  return await axiosInstance("/ajax").post("/diary/insertRcmdDiaryProc.do", objToFormData(payload));
};

/**
 * @title [065] 내 일기장 정보 API
 * @gubun : all(비필수)
 */
export const getMyDiaryInfoAPI = async (payload) => {
  return await axiosInstance("/ajax").get(`/diary/selectMyDiaryInfo.do`, { params: { ...payload } });
};

/**
 * @title [066] 내일기 일자별 목록 API
 * @startNum : 일기 페이지 시작(기본값 0, 필수)
 * @endNum : 일기 페이지 개수(기본값 10, 선택)
 * @searchDate : 조회 기준일(YYYY / YYYYMM)
 * @diaryTypeCd : 일기유형코드 (COMMONCD007000000001)
 */
export const getMyDiaryDailyAPI = async (payload) => {
  return await axiosInstance(`/ajax`).get(`/diary/selectMyDiaryDaily.do`, { params: { ...payload } });
};

/**
 * @title [067] 내일기 월별 목록 API
 * @startNum : 일기 페이지 시작(기본값 0, 비필수)
 * @endNum : 일기 페이지 갯수(기본값 10)
 * @searchDate : 조회기준일(YYYY, string)
 */
export const getMyDiaryMonthAPI = async (payload) => {
  return await axiosInstance(`/ajax`).get(`/diary/selectMyDiaryMonthly.do`, { params: { ...payload } });
};

/**
 * @title [068] 내 일기 연도별 목록 API
 * @startNum : 일기 페이지 시작(기본값 0, 필수)
 * @endNum : 일기 페이지 개수(기본값 10, 비필수)
 * @searchDate : 조회 기준일(YYYY)
 */
export const getMyDiaryYearAPI = async (payload) => {
  return await axiosInstance(`/ajax`).get(`/diary/selectMyDiaryYearly.do`, { params: { ...payload } });
};

/**
 * @title [074-01] 동행일기북 만들기 API
 */
export const getDiaryBookAuthKeyAPI = async () => {
  return await axiosInstance(`/ajax`).get(`/util/selectUserAuthKey.do`);
};

/**
 * @title [074-02] 일기파일 저장 API
 * @memCd
 * @startDate : 다운로드할 시작일 : yyyymmdd
 * @endDate
 * @siteLangCd : 언어코드
 *
 */
export const saveMyDiaryFileAPI = async (payload) => {
  return await axiosInstance(`/ajax`).post(`/diary/downloadDiary.do`, objToFormData(payload));
};
