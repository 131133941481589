<template>
  <v-dialog
    v-model="commonStore.todayNoticePop.open"
    scrollable
    max-height="80vh"
    min-height="30vh"
    transition="dialog-bottom-transition"
    class="jw-bottom-sheet"
    content-class="jw-bottom-sheet--card"
    :max-width="isPc ? '902px' : undefined"
  >
    <v-card class="rounded-t-xl">
      <v-card-title class="text-center align-center">{{ commonStore.todayNoticePop.pop.postTitle }}</v-card-title>
      <v-card-text v-html="commonStore.todayNoticePop.pop.postCnts" />

      <v-divider />
      <v-card-actions>
        <v-btn variant="text" color="grey" @click="commonStore.setPreventNoticePop">다시보지 않기</v-btn>
        <v-spacer />
        <v-btn variant="text" @click="commonStore.todayNoticePop.open = false">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useDisplay } from "vuetify";
const { xlAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);
import { useCommonStore } from "~/store/common";
const commonStore = useCommonStore();
</script>
